import { createSlice } from "@reduxjs/toolkit";
import api from "../Core/axiosConfig";
import { confirmModal } from "./uiStateReducer";

const initialState = {
  notifications: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    onNotificationRead: (state, action) => {
      state.notifications.forEach((item) => {
        if(item.id === action.payload){
          item.seen = true;
          return;
        }
      })
    },
  },
});

export const loginUser = (data) => (dispatch) => {
  const { email, password, navigate } = data;
  api
    .post("/login", { email, password })
    .then((res) => {
      dispatch(setError());
      const data = {
        id: res.data.id,
        companyId: res.data.companyId,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        token: res.data.token,
        companyLogo: res.data.companyLogo,
        companyName: res.data.companyName,
        root: res.data.root,
        superUser: res.data.superUser,
      };
      api.defaults.headers.common["Authorization"] = data.token;
      dispatch(setUser(data));
      localStorage.setItem("token", data.token);
      navigate("/home", { replace: true });
    })
    .catch((err) => dispatch(setError(err.response.data.msg)));
};

export const logOutUser = (navigate) => (dispatch) => {
  localStorage.removeItem("token");
  delete api.defaults.headers.common["Authorization"];
  navigate("/login");
  dispatch(setUser(null));
};

export const updateUser = (navigate) => (dispatch) => {
  api
    .get("/user")
    .then((res) => dispatch(setUser({...res.data})))
    .catch(() => dispatch(logOutUser(navigate)));
};

//NOTIFICATIONS

export const getNotifications = () => (dispatch) => {
  api
    .get("/notifications")
    .then((res) => dispatch(setNotifications(res.data)))
    .catch((err) => console.log(err));
};

export const notificationRead = (id) => (dispatch) => {
  api
    .put(`/seen?id=${id}`)
    .then((res) => {
      dispatch(onNotificationRead(id));
    })
    .catch((err) => console.log(err));
};

export const createNotification = (params) => (dispatch) => {
  const { title, content, companyId, link } = params;
  api
    .post(`/addnotification?title=${title}&content=${content}&company_id=${companyId}&link=${link}`)
    .then(() => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

// ADD USER 
export const addUser = (data) => (dispatch) => {
  api
    .post("/user", data)
    .then((res) => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

export const { setUser, setError, setNotifications, onNotificationRead } = userSlice.actions;

export default userSlice.reducer;
