import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import "../Card.scss";
import { useSelector } from "react-redux";
import { deletePlace, deleteCategory, likeFolder } from "../../../reducers/mediaReducer";
import media from "../../../Assets/Images/test1.jpg";
import { Card } from "react-bootstrap";
import { IconContext } from "react-icons";
import { AiFillFolder } from "react-icons/ai";
import { BiTrash, BiPencil } from "react-icons/bi";
import { LikeHeart } from "../../Heart/LikeHeart/LikeHeart";
import { UnlikeHeart } from "../../Heart/UnlikeHeart/UlikeHeart";
import AddToRecommended from "../AddToRecommended";
import DeleteModal from "../../../Modals/DeleteModal";
import EditPlaceModal from "../../../Modals/EditFolderModal";
import EditCategoryModal from "../../../Modals/EditCategoryModal";

export const FolderCard = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const user = useSelector((state) => state.user.user);
  const [showPlaceDeleteModal, setShowPlaceDeleteModal] = useState(false);
  const [showPlaceEditModal, setShowPlaceEditModal] = useState(false);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
  const [showCategoryEditModal, setShowCategoryEditModal] = useState(false);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setMenuOpen(!isMenuOpen);
  };

  const handleClickCategoryDelete = (event) => {
    event.stopPropagation();
    setShowCategoryDeleteModal(true);
  }

  const handleClickCategoryEdit = (event) => {
    event.stopPropagation();
    setShowCategoryEditModal(true);
  }

  return (
    <div className={`position-relative folder-card-box-${props.size}`}>
      <div
        className={`position-absolute folder-under-card folder-card-${props.size} folder-under-card-${props.size}`}
      ></div>
      <Card
        className={`position-relative folder-card folder-card-${props.size}`}
        role="button"
        onClick={props.onClick}
      >
        {!props.categories &&
          !props.uploads &&
          (!props.favorite ? (
            <LikeHeart id={props.id} action={likeFolder} />
          ) : (
            <UnlikeHeart id={props.id} action={likeFolder} />
          ))}
        {(user?.superUser && pathname.startsWith('/category/')) && (
          <>
            <div
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setShowPlaceEditModal(true);
              }}
              className="position-absolute d-flex justify-content-center align-items-center pencil "
            >
              <IconContext.Provider value={{ className: "pencil-icon" }}>
                <BiPencil />
              </IconContext.Provider>
            </div>
            <div
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setShowPlaceDeleteModal(true);
              }}
              className="position-absolute d-flex justify-content-center align-items-center trash "
            >
              <IconContext.Provider value={{ className: "trash-icon" }}>
                <BiTrash />
              </IconContext.Provider>
            </div>
          </>
        )}
        <img
          alt="folder"
          loading="lazy"
          src={props.media ? props.media : media}
          className="folder-image"
        />
        <Card.Body className="d-flex align-items-end justify-content-between flex-wrap" style={{ padding: '0.75rem 0.75rem' }}>
          {!props.categories && user?.root ? (
            <Card.Title className="category-title mb-0" style={{ maxWidth: '80%' }} >
              <div className="d-flex align-items-center">
                <div style={{ minWidth: '20px' }} >
                  <IconContext.Provider value={{ className: "folder-icon mb-1" }}>
                    <AiFillFolder size={18} />
                  </IconContext.Provider>
                </div>
                <div>
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={props.title}
                  >
                    <h6 className={`long-title-${props.size}`}>
                      {t(props.title)}
                    </h6>
                  </span>
                </div>
              </div>
            </Card.Title>
          ) : (
            <Card.Title className="category-title mb-0 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div style={{ minWidth: '20px' }}>
                  <IconContext.Provider value={{ className: "folder-icon mb-1" }}>
                    <AiFillFolder size={18} />
                  </IconContext.Provider>
                </div>
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title={props.title}
                >
                  <h6 className={`long-title-${props.size}`}>
                    {t(props.title)}
                  </h6>
                </span>
              </div>
              {props.id && user?.superUser && (
                <div className="dropdown" onClick={(event) => toggleMenu(event)}>
                  <span className="dot">...</span>
                  {isMenuOpen && (
                    <div className="menu">
                      <div className="menu-option" onClick={(event) => handleClickCategoryEdit(event)}>Edit</div>
                      <div className="menu-option" onClick={(event) => handleClickCategoryDelete(event)}>
                        <span className="text-danger">Delete</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Card.Title>
          )}
          <div className="d-flex align-items-center mb-0" style={{ marginLeft: 'auto' }}>
            {props.count && (
              <div className="media-text mb-0 me-1">{props.count}</div>
            )}
            {!props.categories && user?.root && (
              <AddToRecommended
                handle={`name=${props.title}`}
                isFolder={true}
                isHome={props.isHome}
                isFavorite={props.isFavorite}
                isRecommended={props.recommended}
                id={props.id}
              />
            )}
          </div>
        </Card.Body>
      </Card>
      {showPlaceDeleteModal && (<DeleteModal
        id={props.id}
        deleteFunction={deletePlace}
        modalTitle={`Delete folder '${props.title}'?`}
        modalText="Are you sure you want to delete this folder ? This action cannot be reverted!"
        categoryId={props.category_id}
        show={showPlaceDeleteModal}
        onHide={() => setShowPlaceDeleteModal(false)}
      />)}
      {showPlaceEditModal && (
        <EditPlaceModal
          place={props}
          show={showPlaceEditModal}
          onHide={() => setShowPlaceEditModal(false)}
        />)}

      {showCategoryDeleteModal && (<DeleteModal
        id={props.id}
        deleteFunction={deleteCategory}
        modalTitle={`Delete category '${props.title}'?`}
        modalText="Are you sure you want to delete this category? This action cannot be reverted!"
        show={showCategoryDeleteModal}
        onHide={() => setShowCategoryDeleteModal(false)}
      />)}
      {showCategoryEditModal && (
        <EditCategoryModal
          category={props}
          show={showCategoryEditModal}
          onHide={() => setShowCategoryEditModal(false)}
        />)}
    </div>
  );
};
