import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sort } from "../../Components/Sort/Sort";
import MediaCard from "../../Components/Card/MediaCard/MediaCard";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPanoramas, getUserUploads, panoramas, getCategories, getPlaces } from "../../reducers/mediaReducer";
import { Spinner } from "react-bootstrap";
import { ascending, descending, titleAZ, titleZA } from "../../helpers/helpers";

const Panoramas = () => {
  const navigate = useNavigate();
  const id = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const panoramasData = useSelector((state) => state.media.panoramas);
  const loading = useSelector((state) => state.uiState.loading);
  const categories = useSelector((state) => state.media.categories)

  const [category, setCategory] = useState(null);

  useEffect(() => {
    if(panoramasData?.length > 0 && categories?.length > 0){
      var categoryObject = categories.find(item => {
        return item.title === panoramasData[0].category_name;
      })
      setCategory(categoryObject);
    }
  }, [panoramasData, categories]);

  useEffect(() => {
    id.id === "useruploads" ? dispatch(getUserUploads()) : dispatch(getPanoramas(id.id));

    dispatch(getCategories());
    dispatch(getPlaces());

    return () => dispatch(panoramas([]));
    //  eslint-disable-next-line
  }, []);

  const titleAZSort = (setActiveSort) => {
    titleAZ(panoramasData, dispatch, panoramas, setActiveSort, ['display_title']);
  };

  const titleZASort = (setActiveSort) => {
    titleZA(panoramasData, dispatch, panoramas, setActiveSort, ['display_title']);
  };

  const viewsAscendingSort = (setActiveSort) => {
    ascending(panoramasData, dispatch, panoramas, setActiveSort, ["count", "title"]);
  };

  const viewsDescendingSort = (setActiveSort) => {
    descending(panoramasData, dispatch, panoramas, setActiveSort, ["count", "title"]);
  };

  return (
    <div className="page-container">
      <div className="d-flex justify-content-between align-items-center mb-subheader">
        <div className="subheading-subtle ml-10">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-categories">
              {(!panoramasData[0]?.category_name && !panoramasData[0]?.category_name) ? (
                <li className="breadcrumb-item" onClick={() => navigate(-1)} role="button">
                  {t("back")}
                </li>
              ) : (
                <>
                  <li className="breadcrumb-item">
                    <Link to="/categories">{t("categories")}</Link>
                  </li>
                  {category?.count_places > 1 && panoramasData[0]?.category_name && (
                    <li className="breadcrumb-item" onClick={() => navigate(`/category/${category.id}`)}>
                      <Link to=""> {t(panoramasData[0]?.category_name)} </Link>
                    </li>
                  )}
                  {panoramasData[0]?.category_name && (<li className="breadcrumb-item active" aria-current="page">
                    {t(panoramasData[0]?.place_name)}
                  </li>
                  )}
                </>
              )}
            </ol>
          </nav>
        </div>
        <div>
          <Sort titleAZSort={titleAZSort} titleZASort={titleZASort} viewsAscendingSort={viewsAscendingSort} viewsDescendingSort={viewsDescendingSort} />
        </div>
      </div >
      {!loading ? (
        <div className="d-flex justify-content-left align-items-left flex-wrap">
          {panoramasData.map((panorama) => {
            return <MediaCard key={panorama.id} panorama={panorama} img={panorama.thumbnail} />;
          })}
        </div>
      ) : (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
    </div >
  );
};

export default Panoramas;
