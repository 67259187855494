import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FiPlay } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { playSound } from "../../reducers/mediaReducer";

export default function PlayMusic(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sounds = useSelector((state) => state.media.sounds);

  const onPlay = (sound) => {
    dispatch(playSound(sound));
    props.onHide();
  };
  //play sound

  return (
    <Modal {...props} size="lg" centered className="play-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          {t("playMusic")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {/* eslint-disable array-callback-return */ }
          {sounds?.map((sound) => {
            if (sound.title !== 'Inget ljud spelas') {
              return (
                <div key={sound.source} className="col-6">
                  <div className="d-flex justify-content-between align-items-center play-item">
                    <div>{sound.title}</div>
                    <IconContext.Provider value={{ className: "play-icon" }}>
                      <FiPlay role="button" onClick={() => onPlay(sound)} />
                    </IconContext.Provider>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}
