import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import api from "../../Core/axiosConfig";
import { confirmModal, errorModal } from "../../reducers/uiStateReducer";

const Settings = () => {
    const dispatch = useDispatch();

    const handleUpdateContent = () => {
        api.post('reset-panoramas')
            .then(() => {
                dispatch(confirmModal())
            })
            .catch((err) => {
                let message = "Error";
                if (err?.response?.data) {
                    message = err?.response?.data?.msg;
                }
                dispatch(errorModal(message))
            });
    }

    return (
        <div className="d-flex">
            <Button variant="success" className="mt-3" onClick={handleUpdateContent}>
                Update content
            </Button>
        </div>
    );
};

export default Settings;
