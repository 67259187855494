import React from "react";
import { CloseButton, Modal } from "react-bootstrap";
import "../Modal.scss";
import { useSelector } from "react-redux";

export default function ViewPanoramaModal(props) {
  const handle = useSelector((state) => state.media.nowPlaying?.handle);

  return (
    <Modal {...props} fullscreen className="panorama-modal p-0 bg-transparent">
      <CloseButton onClick={props.onHide} className="me-3 mt-3 align-self-end" />
      <img src={handle} alt="panorama"/>
    </Modal>
  );
}
