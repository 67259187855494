import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import mediaReducer from "../reducers/mediaReducer";
import userReducer from "../reducers/userReducer";
import uiStateReducer from "../reducers/uiStateReducer";

const reducer = combineReducers({
  user: userReducer,
  media: mediaReducer,
  uiState: uiStateReducer
});

export const Store = configureStore({
  reducer,
});