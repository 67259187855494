import React, { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import instance from "../../Core/axiosConfig";

const Reports = () => {
  const companies = useSelector((state) => state.media.companies);

  const [inputs, setInputs] = useState({
    company_id: companies[0]?.id?.toString(),
    start_date: "",
    end_date: "",
  });
  const [reports, setReports] = useState();
  const [validate, setValidate] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);
    const form = e.currentTarget;
    if (form.checkValidity()) {
      instance
        .post("/reports", inputs)
        .then((res) => setReports(res.data))
        .catch((err) => console.log(err));
    }
  };

  const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <div className="subheading-modal ml-10 mb-3">Check Reports</div>
      <Form noValidate validated={validate} onSubmit={onSubmit} className="form-width d-flex flex-column align-items-center justify-content-center">
        <Form.Label>Company</Form.Label>
        <Form.Select required name="company_id" value={inputs.company_id} onChange={onChange} className="mb-3">
          {companies?.map((company, i) => {
            return (
              <option key={i} value={company.id}>
                {company.name}
              </option>
            );
          })}
        </Form.Select>
        <Form.Control.Feedback type="invalid">Company is required</Form.Control.Feedback>
        <Form.Label>Start Date</Form.Label>
        <Form.Control required type="date" name="start_date" value={inputs.start_date} className="mb-3" onChange={onChange} />

        <Form.Control.Feedback type="invalid">Start date is required</Form.Control.Feedback>

        <Form.Label>End Date</Form.Label>
        <Form.Control required type="date" name="end_date" value={inputs.end_date} onChange={onChange} className="mb-3" />
        <Form.Control.Feedback type="invalid">End date is required</Form.Control.Feedback>

        <Button variant="success" type="submit" className="mt-1">
          Get Reports
        </Button>
      </Form>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Device Name</th>
            <th>End Time</th>
            <th>Time Spent</th>
          </tr>
        </thead>
        <tbody>
          {reports?.map((report, i) => {
            return (
              <tr key={i}>
                <td>{report.device_name}</td>
                <td>{report.end_time}</td>
                <td>{report.time_spent}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Reports;
