import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./ForgotPassword.scss";
import { BiEnvelope } from "react-icons/bi";
import { FiPhone, FiArrowLeft } from "react-icons/fi";

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <div className="cont">
      <div className="page-container d-flex align-items-center justify-content-center mt-0">
        <div className="d-flex flex-column align-items-center justify-content-center w-75 mb-1 p-4 mt-3">
          <Link to="/login" className="small-text-subtle fs-6 align-self-start">
            <FiArrowLeft />{t("backToLogin")}
          </Link>
          <div className="d-flex flex-column align-items-left justify-content-center w-100 border mb-1 p-5 mt-3">
            <div className="title mb-3"><b>{t("forgotPasswordTitle")}</b></div>
            <div className="text">{t("forgotPasswordText")}</div>
            <br />
            <div className="info mb-2 mt-3">
              <FiPhone size={16} className="mr-10"/> 040 - 564 06 77
            </div>
            <div className="info">
              <BiEnvelope size={16} className="mr-10"/> support@virotea.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
