import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { IconContext } from "react-icons";
import { AiOutlineSearch } from "react-icons/ai";
import "../Header/Header.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SearchForm = ({ searchDropdown, setSearchDropdown }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [term, setTerm] = useState("");
  const sidebar = useSelector((state) => state.uiState.sidebar);

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/search/${term}`);
    setTerm("");
    searchDropdown && setSearchDropdown(false);
  };
  //pass search term and navigate to redux action, reset term and hide dropdown

  return (
    <Form className={sidebar ? "d-flex align-items-center open-sidebar-form" : "d-flex align-items-center small-form"} onSubmit={onSubmit}>
      <Form.Group className="position-relative search-box">
        <Form.Control autoFocus={searchDropdown} type="text" placeholder={t("search")} className="textfield textfield-primary search-small" value={term} onChange={(e) => setTerm(e.target.value)} />
        <IconContext.Provider value={{ className: "search-icon" }}>
          <AiOutlineSearch onClick={onSubmit} />
        </IconContext.Provider>
      </Form.Group>
      <button type="submit" size="lg" className="btn btn-lg tertiary-button d-none d-lg-block">
        {t("search")}
      </button>
    </Form>
  );
};

export default SearchForm;
