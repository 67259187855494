import React, { useEffect } from "react";
import { FolderCard } from "../../Components/Card/FolderCard/FolderCard";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, setCategoryTitle } from "../../reducers/mediaReducer";
import { useNavigate } from "react-router-dom";
import img from "../../Assets/Images/folder-placeholder.png";
import { Spinner } from "react-bootstrap";
import { baseURL } from "../../Core/axiosConfig";

export const Categories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.media.categories);

  useEffect(() => {
    dispatch(getCategories());
  //  eslint-disable-next-line
}, []);

  const onClick = (category) => {
    dispatch(setCategoryTitle(category.title));
    if(category.count_places === 1){
      navigate(`/panoramas/${category.places[0].id}`);
      return;
    }
    navigate(`/category/${category.id}`);
  };
  //navigate to category on category click

  const onFolderClick = (path) => {
    navigate(`/panoramas/${path}`);
  };

  return (
    <div className="page-container">
      {categories.length > 0 ? (
        <div className="d-flex justify-content-start w-100 align-items-center flex-wrap">
          {categories.map((category) => {
            return (
              <FolderCard
                id={category.id}
                key={category.id}
                onClick={() => onClick(category)}
                title={category.title}
                media={category.thumbnail ? `${baseURL}/categoryThumbnail/${category.id}` : null}
                categories={true}
                countPlaces={category.count_places}
                size={category.large ? "large" : "small"}
                title_sw={category.title_sw}
                sort={category.sort}
              />
            );
          })}
          <FolderCard media={img} onClick={() => onFolderClick("useruploads")} title="My Uploads" size="small" countPlaces={0} categories={true} uploads={true} />
        </div>
      ) : (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
    </div>
  );
};
