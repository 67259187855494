import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  confirm: false,
  error: false,
  errorMessage: null,
  sidebar: false,
  size: null,
  startedPanorama: false,
};

export const uiStateSlice = createSlice({
  name: "uiState",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = !state.loading;
    },
    confirmModal: (state, startedPanorama = false) => {
      state.confirm = !state.confirm;
      state.startedPanorama = startedPanorama;
    },
    errorModal: (state, errorMessage) => {
      state.error = !state.error;
      state.errorMessage = errorMessage.payload;
    },
    sidebarOpen: (state, action) => {
      state.sidebar = action.payload
    },
    setSize: (state, action) => {
      state.size = action.payload
    }, 
    resetStartedPanorma: (state) => {
      state.startedPanorama = false;
    }
  },
});

export const { loading, confirmModal, errorModal, sidebarOpen, setSize, resetStartedPanorma } = uiStateSlice.actions;

export default uiStateSlice.reducer;
