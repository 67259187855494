import { createSlice } from "@reduxjs/toolkit";
import api from "../Core/axiosConfig";
import { confirmModal, errorModal, loading } from "./uiStateReducer";
import _ from "lodash";

const initialState = {
  nowPlaying: null,
  sound: null,
  panoramas: [],
  error: null,
  categories: [],
  notifications: [],
  favoriteFolders: [],
  companies: [],
  popular: [],
  recommended: [],
  lastPlayed: [],
  places: [],
  categoryTitle: '',
  companyUsers: [],
  searchTerm: '',
  panorama: null,
};

export const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    nowPlaying: (state, action) => {
      state.nowPlaying = action.payload;
    },
    setSound: (state, action) => {
      state.sound = action.payload;
    },
    panoramas: (state, action) => {
      state.panoramas = action.payload;
    },
    setPopular: (state, action) => {
      state.popular = action.payload;
    },
    setRecommended: (state, action) => {
      state.recommended = action.payload;
    },
    setLastPlayed: (state, action) => {
      state.lastPlayed = action.payload;
    },
    onHomeLike: (state, action) => {
      state.popular.forEach((item) => {
        if (item.id === action.payload.id) {
          item.favorite = action.payload.favorite;
        }
      });
      state.recommended.forEach((item) => {
        if (item.id === action.payload.id && !item.category_id) {
          item.favorite = action.payload.favorite;
        }
      });
      state.lastPlayed.forEach(item => {
        if (item.id === action.payload.id) {
          item.favorite = action.payload.favorite;
        }
      })
    },
    onHomeFolderLike: (state, id) => {
      state.recommended.forEach((item) => {
        if (item.category_id && item.id === id.payload) {
          item.favorite = !item.favorite;
        }
      });
    },
    onHomeFolderRecommended: (state, id) => {
      state.recommended.forEach((item) => {
        if (item.id === id.payload && item.category_id) {
          item.recommended = !item.recommended;
        }
      });
    },
    onHomeRecommended: (state, id) => {
      state.popular.forEach((item) => {
        if (item.id === id.payload) {
          item.recommended = !item.recommended;
        }
      });
      state.recommended.forEach((item) => {
        if (item.id === id.payload && !item.category_id) {
          item.recommended = !item.recommended;
        }
      });
      state.lastPlayed.forEach(item => {
        if (item.id === id.payload) {
          item.recommended = !item.recommended;
        }
      })
    },
    onRecommendedMedia: (state, id) => {
      state.panoramas.forEach((element) => {
        if (element.id === id.payload) {
          element.recommended = !element.recommended;
        }
      })
    },
    onRecommendedFolder: (state, id) => {
      state.category.forEach((element) => {
        if (element.id === id.payload) {
          element.recommended = !element.recommended;
        }
      })
    },
    onRecommendedFavoriteFolder: (state, id) => {
      state.favoriteFolders.forEach((element) => {
        if (element.id === id.payload) {
          element.recommended = !element.recommended;
        }
      })
    },
    onPanoramaLike: (state, action) => {
      state.panoramas.forEach((item) => {
        if (item.id === action.payload.id) {
          item.favorite = action.payload.favorite;
        }
      });
    },
    onPanoramaFavoritesLike: (state, action) => {
      let index = _.findIndex(state.panoramas, function (item) {
        return item.id === action.payload.id;
      });
      index !== -1 ? state.panoramas.splice(index, 1) : state.panoramas.unshift(action.payload);
    },
    favoriteFolders: (state, action) => {
      state.favoriteFolders = action.payload;
    },
    onFolderLike: (state, action) => {
      if (state.category) {
        state.category.forEach((item) => {
          if (item.id === action.payload.id) {
            item.favorite = action.payload.favorite;
          }
        });
        return;
      }
    },
    onFolderFavoritesLike: (state, action) => {
      let index = _.findIndex(state.favoriteFolders, function (item) {
        return item.id === action.payload.id;
      });
      index !== -1 ? state.favoriteFolders.splice(index, 1) : state.favoriteFolders.unshift(action.payload);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    sounds: (state, action) => {
      state.sounds = action.payload;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setPlaces: (state, action) => {
      state.places = action.payload;
    },
    setCategoryTitle: (state, title) => {
      state.categoryTitle = title.payload;
    },
    setCompanyUsers: (state, action) => {
      state.companyUsers = action.payload;
    },
    setSearchTerm: (state, term) => {
      state.searchTerm = term.payload;
    },
    setPanorama: (state, action) => {
      state.panorama = action.payload;
    },
  },
});

//GET PANORAMAS, CATEGORIES ETC.

export const likeAction = (data) => (dispatch, getState) => {
  let params = {
    id: data?.id,
    match: data?.match,
  };
  let playing = getState().media.nowPlaying;

  api
    .post(`/favorite/${params.id}`)
    .then((res) => {
      if (params.match === "/favorites") {
        dispatch(onPanoramaFavoritesLike(res.data));
      } else if (params.match === "/home") {
        dispatch(onHomeLike(res.data));
      } else {
        dispatch(onPanoramaLike(res.data));
      }

      if (playing?.id === res.data.id) {
        dispatch(nowPlaying({ ...playing, favorite: res.data.favorite }));
      }
    })
    .catch((err) => console.log(err));
};

export const likeFolder = (data) => (dispatch) => {
  const { id, match } = data;

  api
    .post(`/favplace/${id}`)
    .then((res) => {
      if (match === "/favorites") {
        dispatch(onFolderFavoritesLike(res.data));
      } else if (match === "/home") {
        dispatch(onHomeFolderLike(id));
      } else {
        dispatch(onFolderLike(res.data));
      }
    })
    .catch((err) => console.log(err));
};

//HOME
export const getPopular = () => (dispatch, getState) => {

  api
    .get("/popular")
    .then((res) => dispatch(setPopular(res.data)))
    .catch((err) => dispatch(setError(err.response.status)));
};

export const getRecommended = () => (dispatch) => {
  api
    .get("/getrecommended")
    .then((res) => dispatch(setRecommended(res.data)))
    .catch((err) => console.log(err));
};

export const getLastPlayed = () => (dispatch) => {
  api
    .get("/getLastPlayedPanoramas")
    .then((res) => dispatch(setLastPlayed(res.data)))
    .catch((err) => console.log(err));
}

//FAVORITES

export const getFavorites = () => (dispatch) => {
  dispatch(loading());

  api
    .get("/favorites")
    .then((res) => dispatch(panoramas(res.data)))
    .catch((err) => dispatch(setError(err.response.status)));
};

export const getFavoriteFolders = () => (dispatch) => {
  api
    .get("/favplaces")
    .then((res) => dispatch(favoriteFolders(res.data)))
    .catch((err) => dispatch(setError(err.response.status)))
    .finally(() => dispatch(loading()));
};

//RECOMMENDED

export const addToRecommended = (params) => (dispatch) => {
  const { route, handle, id, isFolder, isHome, isFavorite } = params;
  api
    .post(`/${route}?${handle}`)
    .then(() => {
      if (isHome && isFolder) {
        dispatch(onHomeFolderRecommended(id));
      } else if (isHome) {
        dispatch(onHomeRecommended(id));
      } else if (isFavorite && isFolder) {
        dispatch(onRecommendedFavoriteFolder(id))
      } else if (isFolder) {
        dispatch(onRecommendedFolder(id))
      } else {
        dispatch(onRecommendedMedia(id));
      }
      dispatch(confirmModal())
    })
    .catch((err) => console.log(err));
};

//USER/ROOT SEARCH

export const getSearch = (term) => (dispatch) => {
  dispatch(panoramas([]));
  dispatch(loading());

  api
    .get(`/panoramasearch?title=${term.term}`)
    .then((res) => {
      dispatch(panoramas(res.data));
    })
    .catch((err) => dispatch(setError(err.response.status)))
    .finally(() => dispatch(loading()));
};

export const getAdminSearch = (term) => (dispatch) => {
  dispatch(panoramas([]));
  dispatch(loading());

  api
    .post(`/panoramasearch`, { term: term.term })
    .then((res) => {
      dispatch(setSearchTerm(term.term));
      dispatch(panoramas(res.data));
    })
    .catch((err) => dispatch(setError(err.response.status)))
    .finally(() => dispatch(loading()));
};

//CATEGORIES SOUNDS AND COMPANIES

export const getCategories = () => (dispatch) => {
  api
    .get("/category")
    .then((res) => dispatch(setCategories(res.data)))
    .catch((err) => dispatch(setError(err.response.status)));
};

export const getCategory = (id) => (dispatch) => {
  api
    .get(`/places/${id}`)
    .then((res) => {
      dispatch(setCategory(res.data));
    })
    .catch((err) => dispatch(setError(err.response.status)));
};

export const getSounds = () => (dispatch) => {
  api
    .get("/sounds")
    .then((res) => dispatch(sounds(res.data)))
    .catch((err) => dispatch(setError(err.response.status)));
};

export const getCompanies = () => (dispatch) => {
  api
    .get("/companies")
    .then((res) => dispatch(setCompanies(res.data)))
    .catch((err) => dispatch(setError(err.response.status)));
};

//USER UPLOADS AND PANORAMAS

export const getUserUploads = () => (dispatch) => {
  api
    .get("/useruploads")
    .then((res) => {
      dispatch(panoramas(res.data));
    })
    .catch((err) => dispatch(setError(err.response.status)));
};

export const getPanoramas = (id) => (dispatch) => {
  dispatch(loading());
  api
    .get(`/place/${id}/panoramas`)
    .then((res) => {
      dispatch(panoramas(res.data));
    })
    .catch((err) => dispatch(setError(err.response.status)))
    .finally(() => dispatch(loading()));
};

//POST IMAGE OR PANORAMA OR FOLDER OR ADD TO FAVORITES

export const sendImage = (data) => (dispatch) => {
  api
    .post("/image", data)
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
};
export const uploadPanorama = (data) => (dispatch) => {
  api
    .post("/upload", data)
    .then((res) => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

export const addFolder = (data) => (dispatch) => {
  api
    .post("/addfolder", data)
    .then((res) => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

// PLAY SOUND OR PANORAMA AND GET PLAYING STATUS

const panoramaSearch = async (term) => {
  try {
    const response = await api.post("/panoramasearch", {
      term: term,
    });
    if (response.data.length > 0) {
      return response.data[0];
    }
    return null;
  } catch (error) {
    return null;
  }
}

export const playPanorama = (data) => (dispatch) => {
  const { panorama } = data;
  api
    .post("/start", { panoramaId: panorama.id })
    .then(async (res) => {
      let handle = null;
      let embed_url = res.data.embed_url;
      if (res.data.upload === null && !embed_url) {
        handle = await panoramaSearch(panorama.handle);
        if (!handle) {
          handle = await panoramaSearch(panorama.title);
        }
      }
      let play = {
        id: panorama.id,
        panoramaId: panorama.id,
        title: panorama.title,
        img: panorama.thumbnail,
        url: panorama.thumbnail,
        favorite: panorama.favorite,
        type: panorama.type,
        place_id: panorama.place_id,
        place_name: panorama.place_name,
        category_name: panorama.category_name,
        source: panorama.source,
        handle: embed_url || handle?.embed_url || false,
        display_title: panorama.display_title,
        video: panorama.video,
      };
      dispatch(nowPlaying(play));
      if (panorama.type === 'video' || panorama?.source?.includes('player.vimeo.com')) {
        dispatch(setSound(null));
      }
      const startedPanorama = true;
      dispatch(confirmModal(startedPanorama));
    })
    .catch((err) => console.log(err));
};

export const playSound = (sound) => (dispatch) => {
  const soundObject = {
    id: sound.id,
    title: sound.title
  }
  dispatch(setSound(soundObject))
  api
    .post("/playsound", { sound_id: sound.id })
    .then((res) => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

export const stopSound = () => (dispatch) => {
  dispatch(setSound(0))
  api
    .post("/playsound", { sound_id: 0 })
    .then((res) => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

export const getPlayingStatus = () => (dispatch) => {
  api
    .get("/status")
    .then(async (res) => {
      let handle = null;
      let embed_url = res.data.embed_url;
      if (res.data.upload === null && !embed_url) {
        handle = await panoramaSearch(res.data.handle);
        if (!handle) {
          handle = await panoramaSearch(res.data.title);
        }
      }
      let play = res.data;
      play.handle = embed_url || handle?.embed_url || false;
      const sound = {
        id: res.data.current_sound_id
      }
      dispatch(nowPlaying(play));
      dispatch(setSound(sound))
    })
    .catch((err) => console.log(err));
};

// GET, ADD, DELETE PLACE AND CATEGORY
export const getPlaces = () => (dispatch) => {
  api
    .get("/places")
    .then((res) => dispatch(setPlaces(res.data)))
    .catch((err) => dispatch(setError(err.response.status)));
};

export const addPlace = (data) => (dispatch) => {
  api
    .post("/place", data)
    .then((res) => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

export const addCategory = (data) => (dispatch) => {
  api
    .post("/category", data)
    .then((res) => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

export const deletePlace = (id, category_id) => (dispatch) => {
  api
    .delete(`/place/${id}`)
    .then((res) => {
      dispatch(confirmModal())
      dispatch(getCategory(category_id))
    })
    .catch((err) => {
      dispatch(errorModal(err.response.data.msg))
    });
}

export const deleteCategory = (id) => (dispatch) => {
  api
    .delete(`/category/${id}`)
    .then((res) => {
      dispatch(confirmModal())
      dispatch(getCategories())
    })
    .catch((err) => {
      dispatch(errorModal(err.response.data.msg))
    });
}

// ADD COMPANY 
export const addCompany = (data) => (dispatch) => {
  api
    .post("/company", data)
    .then((res) => dispatch(confirmModal()))
    .catch((err) => console.log(err));
};

// Get all company users 
export const getCompanyUsers = () => (dispatch) => {
  api
    .get("/companyUsers")
    .then((res) => dispatch(setCompanyUsers(res.data)))
    .catch((err) => dispatch(setError(err.response.status)));
}

export const deleteMedia = (id, placeId) => (dispatch) => {
  api
    .delete(`/panorama/${id}`)
    .then((res) => {
      dispatch(confirmModal())
      placeId === "useruploads" ? dispatch(getUserUploads()) : dispatch(getPanoramas(placeId));
    })
    .catch((err) => {
      dispatch(errorModal(err.response.data.msg))
    });
}

export const getPanorama = (placeId, panoramaId) => (dispatch) => {
  api
    .get(`place/${placeId}/panoramas/${panoramaId}`)
    .then((res) => dispatch(setPanorama(res.data)))
    .catch((err) => {
      //dispatch(setError(err.response.status))
    });
}

export const {
  onHomeLike,
  onHomeRecommended,
  onHomeFolderLike,
  onHomeFolderRecommended,
  onRecommendedMedia,
  onRecommendedFolder,
  onRecommendedFavoriteFolder,
  setRecommended,
  setPopular,
  onFolderLike,
  onFolderFavoritesLike,
  favoriteFolders,
  onNotificationRead,
  setCompanies,
  onPanoramaLike,
  setError,
  onPanoramaFavoritesLike,
  setCategories,
  setCategory,
  sounds,
  panoramas,
  nowPlaying,
  setLastPlayed,
  setSound,
  setPlaces,
  setCategoryTitle,
  setCompanyUsers,
  setSearchTerm,
  setPanorama,
} = mediaSlice.actions;

export default mediaSlice.reducer;
