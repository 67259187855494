import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ConfirmationModal from "./Modals/ConfirmationModal";
import ErrorModal from "./Modals/ErrorModal";
import Router from "./Router";
import { useDispatch, useSelector } from "react-redux";
import { setSize, sidebarOpen } from "./reducers/uiStateReducer";

function App() {
  const dispatch = useDispatch();

  const size = useSelector((state) => state.uiState.size);

  useEffect(() => {
    const sizing = () => dispatch(setSize(window.innerWidth));
    window.addEventListener("resize", sizing);
    sizing();
    size > 991 && dispatch(sidebarOpen(false));

    return () => window.removeEventListener("resize", sizing);
    //  eslint-disable-next-line
  });

  return (
    <BrowserRouter>
      <Router />
      <ConfirmationModal />
      <ErrorModal />
    </BrowserRouter>
  );
}

export default App;
