import React from "react";
import { useTranslation } from "react-i18next";
import { BsStar, BsStarFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { addToRecommended } from "../../reducers/mediaReducer";

const AddToRecommended = ({ isFolder, isHome = false, isFavorite = false, handle, isRecommended = false, id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClick = (e) => {
    e.stopPropagation();
    let route = isFolder ? "recommendedplace" : "recommended";
    dispatch(addToRecommended({ route, handle, id, isFolder, isHome, isFavorite }));
  };

  return (
    <div className="mb-1">
      {isRecommended ?
        <BsStarFill
          title={t('removeRecommended')}
          onClick={onClick}
          role="button"
          color="green"
        />
        : 
        <BsStar
          title={t('addRecommended')}
          onClick={onClick}
          role="button"
          color="green"
        />
      }
    </div>
  );
};

export default AddToRecommended;
