import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { IconContext } from "react-icons";
import { AiOutlineMail } from "react-icons/ai";
import { BiLockAlt } from "react-icons/bi";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import logo from "../../Assets/Images/Login/login-logo.svg";
import "../Login/Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../reducers/userReducer";

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const error = useSelector((state) => state.user.error);
  
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(loginUser({ email, password, navigate }));
  };

  return (
    <Row className="g-0">
      <Col className="d-none d-md-none d-lg-flex flex-column justify-content-center align-items-center position-relative">
        <div className="text-center mb-4 login-text login-text-left">{t("loginFormLeftText")}</div>
        <Form className="mt-3 form-width" onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Control type="email" placeholder={t("email")} className="textfield-login textfield textfield-primary" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-5">
            <Form.Control type="password" placeholder={t("password")} className="textfield-login textfield textfield-primary" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Form.Text className="d-flex justify-content-end">
              <Link to="/forgot-password" className="small-text small-text-subtle-secondary">
                {t("forgotPassword")}
              </Link>
            </Form.Text>
          </Form.Group>
          <div className="d-grid">
            <span className="d-flex align-items-center justify-content-center error">{error && error}</span>
            <Button size="lg" type="submit" className="primary-button" onClick={handleSubmit}>
              {t("signIn")}
            </Button>
          </div>
        </Form>
        <div className="position-absolute sign-up-position small-text small-text-subtle-secondary">
          <span>{t("notCustomer")}</span>
          <a href="https://virotea.com/" className="small-text-subtle-secondary-bold">
            {" "}
            {t("signUp")}
          </a>
        </div>
      </Col>
      <Col className="login-background d-none d-md-none d-lg-flex flex-column justify-content-center align-items-center pb-5">
        <div className="text-center login-text-welcome">{t("loginWelcomeText")}</div>
        <div className="text-center mb-5 ms-5">
          <img src={logo} alt="logo" />
        </div>
        <div className="text-center login-text login-text-right">{t("loginFormRightText")}</div>
      </Col>
      <Col className="login-background-mobile d-flex d-md-flex d-lg-none flex-column pb-5">
        <div className="d-flex flex-column justify-content-start align-items-center">
          <div className="text-center mt-5 mb-4 ms-3 ms-md-5">
            <img src={logo} alt="logo-mobile" className="logo-mobile" />
          </div>
          <div className="text-center login-text-mobile">{t("loginFormLeftText")}</div>
        </div>
        <div className="d-flex flex-column align-items-center mt-auto">
          <Form className="mt-3 form-width" onSubmit={handleSubmit}>
            <InputGroup className="mb-3 input-group-textfield">
              <IconContext.Provider value={{ size: "1em", className: "login-input-icon" }}>
                <AiOutlineMail />
              </IconContext.Provider>
              <Form.Control type="email" placeholder={t("email")} className="textfield-login textfield textfield-secondary" value={email} onChange={(e) => setEmail(e.target.value)} />
            </InputGroup>
            <InputGroup className="mb-5 input-group-textfield">
              <IconContext.Provider value={{ size: "1em", className: "login-input-icon" }}>
                <BiLockAlt />
              </IconContext.Provider>
              <Form.Control
                type={passwordShown ? "text" : "password"}
                placeholder={t("password")}
                className="textfield-login textfield textfield-secondary textfield-password-mobile"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <IconContext.Provider value={{ size: "1em", className: "eye-icon" }}>
                {!passwordShown ? <IoMdEye onClick={() => setPasswordShown(!passwordShown)} /> : <IoMdEyeOff onClick={() => setPasswordShown(!passwordShown)} />}
              </IconContext.Provider>
            </InputGroup>
            <div className="d-grid gap-2 mb-5">
              <span className="d-flex align-items-center justify-content-center error">{error && error}</span>
              <Button size="lg" type="submit" className="secondary-button">
                {t("signIn")}
              </Button>
            </div>
          </Form>
          <div className="sign-up-text-mobile">
            <span>{t("notCustomer")}</span>
            <a href="#signUp" className="sign-up-text-mobile-bold">
              {" "}
              {t("signUp")}
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
};
