import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Sort } from "../../Components/Sort/Sort";
import MediaCard from "../../Components/Card/MediaCard/MediaCard";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getCategories, getPlaces, getAdminSearch, panoramas, getCompanyUsers } from "../../reducers/mediaReducer";
import { ascending, descending, titleAZ, titleZA } from "../../helpers/helpers";
import { Form } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";

const AddMedia = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [term, setTerm] = useState("");
    const searchTerm = useSelector((state) => state.media.searchTerm);
    const user = useSelector((state) => state.user.user);
    const panoramasData = useSelector((state) => state.media.panoramas);
    const loading = useSelector((state) => state.uiState.loading);

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getPlaces());
        dispatch(getCompanyUsers());
        // eslint-disable-next-line
    }, []);

    const titleAZSort = (setActiveSort) => {
        titleAZ(panoramasData, dispatch, panoramas, setActiveSort);
    };

    const titleZASort = (setActiveSort) => {
        titleZA(panoramasData, dispatch, panoramas, setActiveSort);
    };

    const viewsAscendingSort = (setActiveSort) => {
        ascending(panoramasData, dispatch, panoramas, setActiveSort, ["count", "title"]);
    };

    const viewsDescendingSort = (setActiveSort) => {
        descending(panoramasData, dispatch, panoramas, setActiveSort, ["count", "title"]);
    };

    const outsideSearch = (panorama) => {
        if (user.root && panorama?.tags?.at(0)) {
            let search = {
                handle: panorama.handle,
                tag: panorama.tags?.at(0),
            };

            return search;
        } else {
            return false;
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const dataObject = {
            term: term
        };
        dispatch(getAdminSearch(dataObject));
        dispatch(panoramas([]));
    };

    return (
        <div>
            <Form className="d-flex align-items-center small-form justify-content-center" onSubmit={onSubmit}>
                <Form.Group className="position-relative search-box">
                    <Form.Control type="text" placeholder={t("search")} className="textfield textfield-primary search-small" value={term} onChange={(e) => setTerm(e.target.value)} />
                    <IconContext.Provider value={{ className: "search-icon" }}>
                        <AiOutlineSearch onClick={onSubmit} />
                    </IconContext.Provider>
                </Form.Group>
                <button type="submit" size="lg" className="btn btn-lg tertiary-button d-none d-lg-block">
                    {t("search")}
                </button>
            </Form>
            {/*if loading true show Sinpper, if no search results show no search results, there are results, render them*/}
            {!loading ? (
                panoramasData?.length > 0 ? (
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-subheader ms-2">
                            <div className="d-flex align-items-center">
                                <IconContext.Provider value={{ className: "icon-subtle" }}>
                                    <BsSearch />
                                </IconContext.Provider>
                                <span className="subheading-subtle ml-10">
                                    {t("searchResults")} "{searchTerm}"
                                </span>
                                &nbsp;
                            </div>
                            <div>
                                <Sort titleAZSort={titleAZSort} titleZASort={titleZASort} viewsAscendingSort={viewsAscendingSort} viewsDescendingSort={viewsDescendingSort} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start flex-wrap">
                            {panoramasData.map((panorama, i) => {
                                return <MediaCard key={i} panorama={panorama} img={panorama.thumbnails?.at(-1).url || panorama.thumbnail} searchCard={() => outsideSearch(panorama)} />;
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="subheading-subtle h-100 w-100 d-flex align-items-center justify-content-center">{t("noSearchResults")}</div>
                )
            ) : (
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <Spinner animation="border" variant="secondary" />
                </div>
            )}
        </div>
    );
};

export default AddMedia;
