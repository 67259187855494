import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createNotification } from "../../reducers/userReducer";
import { getCategories, getPlaces } from "../../reducers/mediaReducer";

const CreateNotification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const companies = useSelector((state) => state.media.companies);
  const categories = useSelector((state) => state.media.categories);
  const places = useSelector((state) => state.media.places);

  const [filterPlaces, setFilterPlaces] = useState([]);
  const handleFilterPlaces = (categoryId) => {
    if (places.length > 0) {
      const newPlaceArray = [];
      places.forEach(place => {
        // comparing int and string, need to be ==
        // eslint-disable-next-line
        if (place.category_id == categoryId) {
          newPlaceArray.push(place);
        }
      })
      setFilterPlaces(newPlaceArray);
      //setInputs({ ...inputs, place: "" })
    }
  };

  const [inputs, setInputs] = useState({
    title: "",
    content: "",
    companyId: "all",
    category: "",
    place: "",
    link: null
  });
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getPlaces());
    //  eslint-disable-next-line
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setValidate(true);
    if (inputs.place) {
      inputs.link = `/panoramas/${inputs.place}`;
    }
    else if (inputs.category) {
      inputs.link = `/category/${inputs.category}`;
    }
    const form = e.currentTarget;
    if (form.checkValidity()) {
      dispatch(createNotification(inputs));
      setValidate(false);
      setInputs({
        title: "",
        content: "",
        companyId: "all",
        category: "",
        place: "",
        link: null
      });
    }
  };

  const onChange = (e) => {
    if (e.target.name === "category") {
      handleFilterPlaces(e.target.value);
      setInputs({ ...inputs, category: e.target.value, place: "" })
      return;
    }
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  };

  return (
    <Form noValidate validated={validate} onSubmit={onSubmit} className="form-width d-flex flex-column align-items-center">
      <div className="subheading-modal ml-10  mb-3">Add Notification</div>
      <Form.Label className="mt-2">Send notification to company</Form.Label>
      <Form.Select required name="companyId" value={inputs.companyId} onChange={onChange} className="mb-2">
        <option value="all">Send to all companies</option>
        {companies?.map((company, i) => {
          return (
            <option key={i} value={company.id}>
              {company.name}
            </option>
          );
        })}
      </Form.Select>

      <Form.Label className="mt-2">Notification Title</Form.Label>
      <Form.Control name="title" className="mb-2" placeholder={t("title")} required value={inputs.title} onChange={onChange} />
      <Form.Control.Feedback type="invalid">Title is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Notification Text</Form.Label>
      <Form.Control name="content" as="textarea" className="mb-2" placeholder={t("text")} required value={inputs.content} onChange={onChange} />
      <Form.Control.Feedback type="invalid">Text is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Link to category</Form.Label>
      <Form.Select name="category" value={inputs.category} onChange={onChange} className="mb-2">
        <option value="">Do not link</option>
        {categories?.map((category) => {
          return (
            <option key={category.id} value={category.id}>
              {category.title}
            </option>
          );
        })}
      </Form.Select>

      <Form.Label className="mt-2">Link to subfolder</Form.Label>
      <Form.Select name="place" value={inputs.place} onChange={onChange} className="mb-2">
        <option value="">Do not link</option>
        {filterPlaces?.map((place) => {
          return (
            <option key={place.id} value={place.id}>
              {place.name}
            </option>
          );
        })}
      </Form.Select>

      <Button type="submit" variant="success" className="mt-3">
        Create Notification
      </Button>
    </Form>
  );
};

export default CreateNotification;
