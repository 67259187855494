import React from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { BiBell } from "react-icons/bi";
import logoMobile from "../Assets/Images/Logos/virotea-logo.svg";
import { useTranslation } from "react-i18next";
import "./Modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notification from "../Components/Header/Notification";
import { sidebarOpen } from "../reducers/uiStateReducer";
import { notificationRead } from "../reducers/userReducer";

const Notifications = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const notifications = useSelector((state) => state.user.notifications);

  const handleNotification = (notification) => {
    if (notification.link) {
      if (!user.root && !user.superuser) {
        notifications.forEach((item) => {
          if (!item.seen) {
            dispatch(notificationRead(item.id))
          }
        })
      }
      navigate(notification.link);
      dispatch(sidebarOpen(false));
    }
  }

  return (
    <Modal fullscreen={true} {...props} className="p-0">
      <Modal.Header className="d-flex justify-content-between">
        <div></div>
        <img src={logoMobile} alt="logo" className="ms-5" />
        <CloseButton onClick={props.onHide} className="m-0" />
      </Modal.Header>
      <Modal.Body>
        <div className="subheading-subtle mb-subheader" id="accordionFAQ">
          <div className="subheader">
            <BiBell size={20} />
            &nbsp;&nbsp;{t("notifications")}
          </div>
          {
            notifications.length === 0 ? (
              <div className="notification-modal-item pb-2 mb-2 mt-3" >
                <div className="notification-seen">
                  <Notification
                    title={"NoNotifications"}
                    id={"NoNotifications"}
                    content={"NoNotifications"}
                  />
                </div>
              </div>
            ) : (
              notifications.map((notification, i) => {
                return (
                  <div
                    key={i}
                    className="notification-modal-item pb-2 mb-2 mt-3"
                    onClick={() => handleNotification(notification)}
                  >
                    {notification.seen ? (
                      <div className="notification-seen">
                        <Notification
                          title={notification.title}
                          id={notification.id}
                          content={notification.content}
                          seen={notification.seen}
                          link={notification.link}
                        />
                      </div>
                    ) : (
                      <Notification
                        title={notification.title}
                        id={notification.id}
                        content={notification.content}
                        seen={notification.seen}
                        link={notification.link}
                      />
                    )}
                  </div>
                );
              })
            )
          }
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Notifications;
