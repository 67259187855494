import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import checkIcon from "../../Assets/Icons/check.svg";

export const Sort = ({ titleAZSort, titleZASort, viewsAscendingSort, viewsDescendingSort }) => {
  const { t } = useTranslation();

  const [activeSort, setActiveSort] = useState("");
  const activeSortStyle = "d-flex justify-content-between align-items-center active";

  return (
    <Dropdown className="primary-dropdown sort-dropdown">
      <div className="d-flex align-items-center">
        <Dropdown.Toggle>
          <div className="subheading-subtle mr-10 dropdown-arrow d-flex align-items-center">
            <div className="me-2"> {t("sortBy")}</div>
          </div>
        </Dropdown.Toggle>
      </div>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => titleAZSort(setActiveSort)}>
          <div className={activeSort === "titleAZ" ? activeSortStyle : ""}>
            <div> {t("titleAZ")}</div>
            {activeSort === "titleAZ" && <img src={checkIcon} alt="check" />}
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => titleZASort(setActiveSort)}>
          <div className={activeSort === "titleZA" ? activeSortStyle : ""}>
            <div> {t("titleZA")}</div>
            {activeSort === "titleZA" && <img src={checkIcon} alt="check" />}
          </div>
        </Dropdown.Item>
          <Dropdown.Item onClick={() => viewsAscendingSort(setActiveSort)}>
            <div className={activeSort === "viewsAscending" ? activeSortStyle : ""}>
              <div> {t("viewsAscending")}</div>
              {activeSort === "viewsAscending" && <img src={checkIcon} alt="check" />}
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => viewsDescendingSort(setActiveSort)}>
            <div className={activeSort === "viewsDescending" ? activeSortStyle : ""}>
              <div> {t("viewsDescending")}</div>
              {activeSort === "viewsDescending" && <img src={checkIcon} alt="check" />}
            </div>
          </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
