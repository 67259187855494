import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { BsBookmark, BsStar, BsClock } from "react-icons/bs";
import { Sort } from "../../Components/Sort/Sort";
import { FolderCard } from "../../Components/Card/FolderCard/FolderCard";
import MediaCard from "../../Components/Card/MediaCard/MediaCard";
import { useDispatch, useSelector } from "react-redux";
import { getPopular, getRecommended, setPopular, setRecommended, setLastPlayed, getLastPlayed } from "../../reducers/mediaReducer";
import { ascending, descending, titleAZ, titleZA } from "../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../Core/axiosConfig";

export const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const popular = useSelector((state) => state.media.popular);
  const recommended = useSelector((state) => state.media.recommended);
  const lastPlayed = useSelector((state) => state.media.lastPlayed);

  useEffect(() => {
    dispatch(getPopular());
    dispatch(getRecommended());
    dispatch(getLastPlayed());
    // eslint-disable-next-line
  }, []);

  const onFolderClick = (id) => {
    navigate(`/panoramas/${id}`);
  };

  const titleAZSort = (setActiveSort) => {
    titleAZ(lastPlayed, dispatch, setLastPlayed, setActiveSort, ["display_title"]);
    titleAZ(popular, dispatch, setPopular, setActiveSort, ["display_title"]);
    titleAZ(recommended, dispatch, setRecommended, setActiveSort, ["title"]);
  };

  const titleZASort = (setActiveSort) => {
    titleZA(lastPlayed, dispatch, setLastPlayed, setActiveSort, ["display_title"]);
    titleZA(popular, dispatch, setPopular, setActiveSort, ["display_title"]);
    titleZA(recommended, dispatch, setRecommended, setActiveSort, ["title"]);
  };

  const viewsAscendingSort = (setActiveSort) => {
    ascending(lastPlayed, dispatch, setLastPlayed, setActiveSort, ["panoramaCount", "name"]);
    ascending(popular, dispatch, setPopular, setActiveSort, ["count", "title"]);
    ascending(recommended, dispatch, setRecommended, setActiveSort, ["count", "title"]);
  };

  const viewsDescendingSort = (setActiveSort) => {
    descending(lastPlayed, dispatch, setLastPlayed, setActiveSort, ["panoramaCount", "name"]);
    descending(popular, dispatch, setPopular, setActiveSort, ["count", "title"]);
    descending(recommended, dispatch, setRecommended, setActiveSort, ["count", "title"]);
  };

  return (
    <div className="page-container">
      <div>
        <div className="d-flex justify-content-between align-items-center mb-subheader" style={{ marginTop: '22px' }}>
          <div className="d-flex align-items-center ml-15">
            <IconContext.Provider value={{ className: "icon-subtle" }}>
              <BsBookmark />
            </IconContext.Provider>
            <span className="subheading-subtle ml-10">{t("recommended")}</span>
          </div>
          <div>
            <Sort titleAZSort={titleAZSort} titleZASort={titleZASort} viewsAscendingSort={viewsAscendingSort} viewsDescendingSort={viewsDescendingSort} />
          </div>
        </div>
        <div className="container" style={{ margin: '0px', padding: '0px', maxWidth: '99%' }}>
          <div className="row" style={{ marginLeft: '0.25%' }}>
            {recommended.map((data, i) => {
              return data.category_id ? (
                <div key={`col-${i}`} className="col-lg-6 col-md-6 col-12">
                  <FolderCard
                    key={data.id}
                    favorite={data.favorite}
                    id={data.id}
                    title={data.name}
                    size="large-home"
                    count={data.panoramaCount}
                    onClick={() => onFolderClick(data.id)}
                    recommended={data.recommended}
                    isHome={true}
                    media={data.thumbnail ? `${baseURL}/placeThumbnail/${data.id}` : null}
                  />
                </div>
              ) : (
                <div key={`col-${i}`} className="col-lg-3 col-md-3 col-6" >
                  <MediaCard key={data.id} panorama={data} img={data.thumbnail} isHome={true} homeRecommended={'-home-recommended'} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {lastPlayed && lastPlayed?.length > 0 && (<div style={{ marginTop: '50px' }}>
        <div className="d-flex align-items-center mb-subheader ml-12">
          <IconContext.Provider value={{ className: "icon-subtle" }}>
            <BsClock />
          </IconContext.Provider>
          <span className="subheading-subtle ml-10">{t("recentlyViewed")}</span>
        </div>
        <div className="d-flex justify-content-center flex-wrap">
          {lastPlayed?.map((panorama) => {
            return <MediaCard key={panorama.id} panorama={panorama} img={panorama.thumbnail} isHome={true} />;
          })}
        </div>
      </div>
      )}
      <div style={{ marginTop: '50px' }}>
        <div className="d-flex align-items-center mb-subheader ml-12">
          <IconContext.Provider value={{ className: "icon-subtle" }}>
            <BsStar />
          </IconContext.Provider>
          <span className="subheading-subtle ml-10">{t("popular")}</span>
        </div>
        <div className="d-flex justify-content-center flex-wrap">
          {popular?.map((panorama) => {
            return <MediaCard key={panorama.id} panorama={panorama} img={panorama.thumbnail} isHome={true} />;
          })}
        </div>
      </div>
    </div>
  );
};
