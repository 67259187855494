import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Nav, Navbar, Dropdown, CloseButton, Button } from "react-bootstrap";
import { IconContext } from "react-icons";
import {
  AiOutlineSearch,
  AiOutlineUser,
  AiOutlineBell,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import { FiLogOut, FiPlay } from "react-icons/fi";
import { BiEnvelope, BiUserX } from "react-icons/bi";
import Contact from "../../Modals/Contact/Contact";
import UploadContent from "../../Modals/UploadContent/UploadContent";
import FAQ from "../../Modals/FAQ/FAQ";
import PlayMusic from "../../Modals/PlayMusic/PlayMusic";
import logo from "../../Assets/Images/Logos/logo.svg";
import logoMobile from "../../Assets/Images/Logos/virotea-logo.svg";
import "./Header.scss";
import NotificationDropdown from "./NotificationDropdown";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications, logOutUser, notificationRead } from "../../reducers/userReducer";
import {
  getPlayingStatus,
  getSounds,
  likeAction,
} from "../../reducers/mediaReducer";
import SearchForm from "./SearchForm";
import ViewPanoramaModal from "../../Modals/ViewPanoramaModal/ViewPanoramaModal";
import Flag from "./Flag";
import { sidebarOpen } from "../../reducers/uiStateReducer";
import NowPlayingModal from "../../Modals/NowPlayingModal";
import { like } from "../../helpers/helpers";
import PlayDropdown from "./PlayDropdown";
import { baseURL } from "../../Core/axiosConfig";
import { stopSound } from "../../reducers/mediaReducer";

export const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useLocation();

  const user = useSelector((state) => state.user.user);
  const playing = useSelector((state) => state.media.nowPlaying);
  const sidebar = useSelector((state) => state.uiState.sidebar);
  const notifications = useSelector((state) => state.user.notifications);
  //user and panorama playing redux states
  const [panoramaModal, setPanoramaModal] = useState(false);
  const [contactModalShow, setContactModalShow] = useState(false);
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const [faqModalShow, setFaqModalShow] = useState(false);
  const [playModalShow, setPlayModalShow] = useState(false);
  const [nowPlayingModal, setNowPlayingModal] = useState(false);
  //modal controls

  const [searchDropdown, setSearchDropdown] = useState(false);
  //Search dropdown

  const signOut = () => {
    dispatch(logOutUser(navigate));
    sidebar && dispatch(sidebarOpen(!sidebar));
  };
  //LOGOUT AND NAVIGATE TO LOGIN PAGE

  const onLikeUnlike = () => {
    like(dispatch, likeAction, playing.id, match);
  };

  const notify = () => {
    setInterval(() => {
      dispatch(getNotifications());
    }, 60000);
  };

  useEffect(() => {
    !playing && dispatch(getPlayingStatus());
    dispatch(getSounds());
    dispatch(getNotifications());
    notify();
    //  eslint-disable-next-line
  }, []);

  //GET SOUNDS

  const sidebarController = () => dispatch(sidebarOpen(!sidebar));
  //Sidebar controls

  const [notificationShow, setNotificationShow] = useState(false);
  const [countNotificationUnread, setCountNotificationUnread] = useState(0);
  const handleNotificationOpen = () => {
    if (notificationShow && !user.root && !user.superuser) {
      notifications.forEach((item) => {
        if (!item.seen) {
          dispatch(notificationRead(item.id))
        }
      })
    }
    setNotificationShow(!notificationShow);
  }
  useEffect(() => {
    let counter = 0;
    notifications.forEach((item) => {
      if (!item.seen) {
        counter += 1;
      }
    })
    setCountNotificationUnread(counter);
  }, [notifications]);

  const scrollToTop = (event) => {
    event.preventDefault();
    const navigateLink = event?.target?.href?.replace(window.location.origin, '');
    const currentLink = window.location.pathname;
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    if (navigateLink !== currentLink) {
      navigate(navigateLink);
    }
  }

  const [userLogo, setUserLogo] = useState(null);
  useEffect(() => {
    if (user?.companyLogo) {
      setUserLogo(user?.companyLogo);
    }
  }, [user]);

  const handleStopSound = () => {
    dispatch(stopSound());
  }

  return (
    <>
      <Navbar
        className={sidebar ? "open-header-side" : "fixed-top header-nav"}
        expand="lg"
      >
        {sidebar ? (
          <CloseButton
            onClick={sidebarController}
            variant="white"
            className="d-lg-none close-sidebar-button ms-2"
          />
        ) : (
          <Navbar.Toggle
            onClick={sidebarController}
            aria-controls="offcanvasNavbar"
            className="d-lg-none ms-2 border-0"
          />
        )}
        <Navbar.Brand>
          <div
            className="d-none d-lg-flex ms-5"
            onClick={() => navigate("/home")}
          >
            <img
              src={logo}
              alt="logo"
              className="d-inline-block align-top"
              role="button"
            />
          </div>
          <div className="d-lg-none d-flex ms-5">
            <img
              src={logoMobile}
              alt="logo"
              className="d-inline-block align-top"
            />
          </div>
        </Navbar.Brand>
        <Button
          variant="transparent"
          disabled={!playing}
          className="d-lg-none me-2"
        >
          <IconContext.Provider
            value={{
              className: `dropdown-arrow ${!playing && "play-button"}`,
            }}
          >
            <FiPlay size={25} onClick={() => setNowPlayingModal(true)} />
          </IconContext.Provider>
        </Button>

        {sidebar && (
          <Sidebar
            signOut={signOut}
            siderbar={sidebar}
            sidebarController={sidebarController}
            setFaqModalShow={setFaqModalShow}
            countNotificationUnread={countNotificationUnread}
          />
        )}
        <Navbar.Collapse className="d-none d-lg-flex">
          <Nav className="me-auto">
            <NavLink
              activeclassname="active"
              className="nav-link"
              to="/home"
              onClick={scrollToTop}
            >
              {t("home")}
            </NavLink>
            <NavLink
              activeclassname="active"
              className="nav-link"
              to="/favorites"
              onClick={scrollToTop}
            >
              {t("favorites")}
            </NavLink>
            <NavLink
              activeclassname="active"
              className="nav-link"
              to="/categories"
              onClick={scrollToTop}
            >
              {t("categories")}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
        <div className=" d-none d-lg-flex nav-icons-box">
          <Nav className="nav-play-now-responsive">
            <div className="nav-link nav-link-icon">
              {playing && (
                <PlayDropdown playing={playing} setPlayModalShow={setPlayModalShow} />
              )}
            </div>
          </Nav>
          <Nav className="d-flex nav-icons-responsive">
            <div className="nav-link nav-link-icon">
              <Dropdown
                show={searchDropdown}
                onToggle={() => setSearchDropdown(!searchDropdown)}
                className="header-dropdown primary-dropdown search-dropdown dropdown-position"
              >
                <Dropdown.Toggle>
                  <IconContext.Provider
                    value={{ className: "header-menu-icon" }}
                  >
                    <AiOutlineSearch />
                  </IconContext.Provider>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <SearchForm
                    setSearchDropdown={setSearchDropdown}
                    searchDropdown={searchDropdown}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="nav-link nav-link-icon">
              <Dropdown className="header-dropdown secondary-dropdown user-dropdown dropdown-position">
                <Dropdown.Toggle>
                  <IconContext.Provider
                    value={{ className: "header-menu-icon" }}
                  >
                    <AiOutlineUser />
                  </IconContext.Provider>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="row">
                    <div className="d-flex justify-content-center align-items-center user-header">
                      <div className="col-3">
                        <div className="user-image">
                          {userLogo && (
                            <img
                              src={`${baseURL}/uploads/companies/logo/full-size/${userLogo}`}
                              width="100%"
                              alt="company"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="small-text-brand">{t("signedIn")}</div>
                        <div className="text-username">{user?.companyName}</div>
                      </div>
                    </div>
                  </div>

                  {user?.root && (
                    <Dropdown.Item
                      className="text-user-tab text-danger"
                      onClick={() => {
                        navigate("/add");
                      }}
                    >
                      <IconContext.Provider
                        value={{ className: "user-icon text-danger" }}
                      >
                        <BiUserX size={18} />
                      </IconContext.Provider>
                      {t("admin")}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item className="text-user-tab" onClick={() => setFaqModalShow(true)}>
                    <IconContext.Provider
                      value={{ className: "user-icon" }}
                    >
                      <BsQuestionCircle size={18} />
                    </IconContext.Provider>
                    {t("FAQ")}
                  </Dropdown.Item>
                  <Dropdown.Item className="text-user-tab" onClick={() => setContactModalShow(true)}>
                    <IconContext.Provider
                      value={{ className: "user-icon" }}
                    >
                      <BiEnvelope size={18} />
                    </IconContext.Provider>
                    {t("contactUs")}
                  </Dropdown.Item>

                  <Dropdown.Item className="text-user-tab" onClick={() => setUploadModalShow(true)}>
                    <IconContext.Provider
                      value={{ className: "user-icon" }}
                    >
                      <AiOutlineCloudUpload size={18} />
                    </IconContext.Provider>
                    {t("uploadContent")}
                  </Dropdown.Item>
                  <Dropdown.Item className="text-user-tab" onClick={signOut}>
                    <IconContext.Provider
                      value={{ className: "user-icon" }}
                    >
                      <FiLogOut size={18} />
                    </IconContext.Provider>
                    {t("signOut")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="nav-link nav-link-icon">
              <Dropdown className="header-dropdown primary-dropdown notification-dropdown dropdown-position" onToggle={handleNotificationOpen} show={notificationShow}>
                <Dropdown.Toggle>
                  {countNotificationUnread > 0 && (
                    <i className="position-absolute notifications"></i>
                  )}
                  {/*kad mapiram notifikacije, dodaj conditional +AKO IMA NOTIFIKACIJA RENDERUJ AKO NE, NEMOJ+ ispred notifications className-a*/}
                  <IconContext.Provider
                    value={{ className: "header-menu-icon" }}
                  >
                    <AiOutlineBell />
                  </IconContext.Provider>
                </Dropdown.Toggle>
                <NotificationDropdown />
              </Dropdown>
            </div>
            <div className="nav-link nav-link-icon d-flex align-items-center me-4 ms-3">
              <Flag />
            </div>
          </Nav>
        </div>
      </Navbar>

      <NowPlayingModal
        onLikeUnlike={onLikeUnlike}
        setPlayModalShow={setPlayModalShow}
        setPanoramaModal={setPanoramaModal}
        show={nowPlayingModal}
        onHide={() => setNowPlayingModal(false)}
        handleStopSound={handleStopSound}
      />
      <PlayMusic show={playModalShow} onHide={() => setPlayModalShow(false)} />
      <FAQ
        fullscreen={sidebar}
        show={faqModalShow}
        onHide={() => setFaqModalShow(false)}
      />
      <Contact
        show={contactModalShow}
        onHide={() => setContactModalShow(false)}
      />
      <UploadContent
        show={uploadModalShow}
        onHide={() => setUploadModalShow(false)}
      />
      <ViewPanoramaModal
        show={panoramaModal}
        onHide={() => setPanoramaModal(false)}
      />
    </>
  );
};
