import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import "./Assets/Fonts/geomanist-regular-webfont.woff";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Store } from "./Store/Store";
import App from "./App";
import "./index.scss";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(I18NextHttpBackend)
  .init({
    supportedLngs: ["en", "sw"],
    lng: localStorage.getItem("i18nextLng") || "sw",
    fallbackLng: localStorage.getItem("i18nextLng") || "sw",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const loadingMarkup = "";

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <Provider store={Store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

reportWebVitals();
