import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form, Button } from "react-bootstrap";
import { IconContext } from "react-icons";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useDispatch } from "react-redux";
import instance from "../../Core/axiosConfig";
import Spinner from 'react-bootstrap/Spinner';
import { confirmModal } from "../../reducers/uiStateReducer";

export default function UploadContent(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [progressValue, setProgressValue] = useState(100);

  const [inputs, setInputs] = useState({
    title: "",
    location: "",
    media: null,
  });
  const [validate, setValidate] = useState(false);

  const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });
  //inputs changes

  const onHide = () => {
    setInputs({
      title: "",
      location: "",
      media: null,
    })
    props.onHide()

  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setValidate(true);
    if (loading || loadingVideo) {
      return;
    }
    const form = e.currentTarget;
    if (form.checkValidity()) {
      if (inputs.media.type.indexOf('video/') !== 0 || inputs.media.size < 10 * 1024 * 1024) {
        setLoading(true);
        const data = new FormData();

        data.append("title", inputs.title);
        data.append("place", inputs.location);
        data.append("panorama_image", inputs.media);
        try {
          await instance.post('/upload', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setLoading(false);
          props.onHide();
          dispatch(confirmModal());
        }
        catch (error) {
          setLoading(false);
          console.error(error);
          return;
        }
      } else if (inputs.media.type.indexOf('video/') === 0) {
        setProgressValue(0);
        setLoadingVideo(true);
        const chunkSize = 5 * 1024 * 1024;
        const totalChunks = Math.ceil(inputs.media.size / chunkSize);
        let startByte = 0;
        const fileExtension = inputs.media.name.split('.').pop();

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
          const endByte = Math.min(startByte + chunkSize, inputs.media.size);
          const chunk = inputs.media.slice(startByte, endByte);
          const formData = new FormData();
          formData.append('chunk', chunk);
          formData.append('chunkIndex', chunkIndex);
          formData.append('totalChunks', totalChunks);
          formData.append('fileName', inputs.media.name);
          formData.append('title', inputs.title);
          formData.append('place', inputs.location);
          formData.append('fileExtension', fileExtension);

          try {
            await instance.post('/upload-chunk', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

          } catch (error) {
            setLoadingVideo(false);
            console.error('Error uploading chunk:', error);
            return;
          }

          setProgressValue(((chunkIndex + 1) / totalChunks) * 100);
          startByte = endByte;
        }
        setLoadingVideo(false);
        setProgressValue(100);
        dispatch(confirmModal());
      }
    }
  };

  const formatPercentage = (value) => {
    return `${value.toFixed(2)}%`;
  };

  return (
    <Modal show={props.show} onHide={onHide} size="lg" centered className="upload-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          {t("uploadContent")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <Form onSubmit={onSubmit} className="mt-3 form-width" noValidate validated={validate}>
          <Form.Group className="mb-4">
            <Form.Control required value={inputs.title} name="title" onChange={onChange} type="text" placeholder={t("title")} className="textfield textfield-primary" />
            <Form.Control.Feedback className="ms-1" type="invalid">
              {t("titleRequired")}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Control required value={inputs.location} name="location" onChange={onChange} type="text" placeholder={t("contryOrLocation")} className="textfield textfield-primary" />
            <Form.Control.Feedback className="ms-1" type="invalid">
              {t("locationRequired")}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <div className="upload-box">
              <label htmlFor="file-input" className=" d-flex flex-column align-items-center justify-content-center">
                <IconContext.Provider value={{ className: "upload-icon" }}>
                  <AiOutlineCloudUpload />
                </IconContext.Provider>
                <div className="upload-text">{inputs.media ? inputs.media.name : t("chooseFile")}</div>
                <div className="upload-text">{t("maxUploadSize")} 3GB</div>
                <div className="upload-text">{t("typesAllowed")} .mp4, .png, .jpg, .jpeg</div>
              </label>
            </div>
            <input required name="media" onChange={(e) => setInputs({ ...inputs, media: e.target.files[0] })} accept="image/*, video/*" id="file-input" type="file" className="d-none" />
            <Form.Control.Feedback className="ms-1" type="invalid">
              {t("panoramaRequired")}
            </Form.Control.Feedback>
            {loadingVideo && (
              <div className="showProgress">
                <div className="progress custom-progress-bar">
                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={progressValue} aria-valuemin="0" aria-valuemax="100" style={{ width: formatPercentage(progressValue) }}>
                  </div>
                </div>
                {formatPercentage(progressValue)}
              </div>
            )}
            {loading && (
              <div className="d-flex justify-content-end mt-2">
                <Spinner animation="border" role="status" />
              </div>
            )}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button size="lg" type="submit" className="primary-button">
              {t("upload")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
