import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Header } from "../Components/Header/Header";
import { useSelector } from "react-redux";
import SearchForm from "../Components/Header/SearchForm";

const AdminRoute = () => {
  let token = localStorage.getItem("token");

  const user = useSelector((state) => state.user.user);
  const sidebar = useSelector((state) => state.uiState.sidebar);
  const size = useSelector((state) => state.uiState.size);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (user?.root === false) {
    return <Navigate to="/home" replace />;
  }

  return (
    <>
      <Header />
      <div className={sidebar ? "open-side" : "cont"}>
        <Outlet />
      </div>
      {size < 992 && <SearchForm />}
    </>
  );
};
//Protect routes, navigate to /login if no user, navigate to route he wants if hes logged
export default AdminRoute;
