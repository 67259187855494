import React from "react";
import { useTranslation } from "react-i18next";
import "./Header.scss";
import gb from "../../Assets/Icons/gb.svg";
import se from "../../Assets/Icons/se.svg";

const Flag = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  return <img className="flag" onClick={() => (i18n.language === "sw" ? changeLanguage("en") : changeLanguage("sw"))} src={i18n.language === "sw" ? `${gb}` : `${se}`} alt="flags"/>;
};

export default Flag;
