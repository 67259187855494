import React, { useEffect } from "react";
import { IconContext } from "react-icons";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Sort } from "../../Components/Sort/Sort";
import MediaCard from "../../Components/Card/MediaCard/MediaCard";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getCategories, getPlaces, getSearch, panoramas, getCompanyUsers, setCategoryTitle } from "../../reducers/mediaReducer";
import { useNavigate, useParams } from "react-router-dom";
import { ascending, descending, titleAZ, titleZA } from "../../helpers/helpers";
import { baseURL } from "../../Core/axiosConfig";
import { FolderCard } from "../../Components/Card/FolderCard/FolderCard";

const Search = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const term = useParams();
  const user = useSelector((state) => state.user.user);
  const panoramasData = useSelector((state) => state.media.panoramas);
  const loading = useSelector((state) => state.uiState.loading);

  useEffect(() => {
    dispatch(getSearch(term));
    dispatch(getCategories());
    dispatch(getPlaces());
    dispatch(getCompanyUsers());

    return () => dispatch(panoramas([]));
    //  eslint-disable-next-line
  }, [term.term]);

  const titleAZSort = (setActiveSort) => {
    titleAZ(panoramasData, dispatch, panoramas, setActiveSort);
  };

  const titleZASort = (setActiveSort) => {
    titleZA(panoramasData, dispatch, panoramas, setActiveSort);
  };

  const viewsAscendingSort = (setActiveSort) => {
    ascending(panoramasData, dispatch, panoramas, setActiveSort, ["count", "title"]);
  };

  const viewsDescendingSort = (setActiveSort) => {
    descending(panoramasData, dispatch, panoramas, setActiveSort, ["count", "title"]);
  };

  const outsideSearch = (panorama) => {
    if (user.root && panorama?.tags?.at(0)) {
      let search = {
        handle: panorama.handle,
        tag: panorama.tags?.at(0),
      };

      return search;
    } else {
      return false;
    }
  };

  const onFolderClick = (id) => {
    navigate(`/panoramas/${id}`);
  };

  const onCategoryClick = (category) => {
    dispatch(setCategoryTitle(category.title));
    if(category.count_places === 1){
      navigate(`/panoramas/${category.places[0].id}`);
      return;
    }
    navigate(`/category/${category.id}`);
  };

  return (
    <div className="page-container">
      {/*if loading true show Sinpper, if no search results show no search results, there are results, render them*/}
      {!loading ? (
        panoramasData?.length > 0 ? (
          <div>
            <div className="d-flex justify-content-between align-items-center mb-subheader ms-2">
              <div className="d-flex align-items-center">
                <IconContext.Provider value={{ className: "icon-subtle" }}>
                  <BsSearch />
                </IconContext.Provider>
                <span className="subheading-subtle ml-10">
                  {t("searchResults")} "{term.term}"
                </span>
                &nbsp;
              </div>
              <div>
                <Sort titleAZSort={titleAZSort} titleZASort={titleZASort} viewsAscendingSort={viewsAscendingSort} viewsDescendingSort={viewsDescendingSort} />
              </div>
            </div>
            <div className="d-flex justify-content-start flex-wrap">
              {panoramasData.map((panorama, i) => {
                if (panorama.name) {
                  return <FolderCard
                    key={panorama.id}
                    favorite={panorama.favorite}
                    id={panorama.id}
                    title={panorama.name}
                    size="large"
                    category_id={panorama.category_id}
                    media={panorama.thumbnail ? `${baseURL}/placeThumbnail/${panorama.id}` : null}
                    count={panorama.panoramaCount}
                    onClick={() => onFolderClick(panorama.id)}
                    recommended={panorama.recommended}
                  />
                }
                if (!panorama.source) {
                  return <FolderCard
                    key={panorama.id}
                    onClick={() => onCategoryClick(panorama)}
                    title={panorama.title}
                    media={panorama.thumbnail ? `${baseURL}/categoryThumbnail/${panorama.id}` : null}
                    categories={true}
                    countPlaces={panorama.count_places}
                    size="large"
                  />
                }
                return <MediaCard key={i} panorama={panorama} img={panorama.thumbnails?.at(-1).url || panorama.thumbnail} searchCard={() => outsideSearch(panorama)} />;
              })}
            </div>
          </div>
        ) : (
          <div className="subheading-subtle h-100 w-100 d-flex align-items-center justify-content-center">{t("noSearchResults")}</div>
        )
      ) : (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
    </div>
  );
};

export default Search;
