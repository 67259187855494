import React from "react";
import { IconContext } from "react-icons";
import { AiOutlineHeart } from "react-icons/ai";
import "../Heart.scss";
import { like } from "../../../helpers/helpers";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export const LikeHeart = ({ id, action }) => {
  const dispatch = useDispatch();
  const match = useLocation()

  const onLikeUnlike = () => like(dispatch, action, id, match);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onLikeUnlike();
      }}
      role="button"
      className="position-absolute d-flex justify-content-center align-items-center heart heart-unliked ">
      <IconContext.Provider value={{ className: "heart-icon heart-icon-unliked" }}>
        <AiOutlineHeart />
      </IconContext.Provider>
    </div>
  );
};
