import React from "react";
import { useTranslation } from "react-i18next";
import { CloseButton, Modal } from "react-bootstrap";
import logoMobile from "../../Assets/Images/Logos/virotea-logo.svg";
import { BsQuestionCircle } from "react-icons/bs";

export default function FAQ({ fullscreen, show, onHide }) {
  const { t } = useTranslation();

  return (
    <Modal fullscreen={fullscreen} show={show} onHide={onHide} size="lg" centered className="faq-modal p-0 mb-0">
      <Modal.Header className="d-flex justify-content-between">
        <div></div>
        {fullscreen ? (
          <img src={logoMobile} alt="logo" className="ms-5"/>
        ) : (
          <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
            {t("faq")}
          </Modal.Title>
        )}
        <CloseButton onClick={onHide} className="m-0"/>
      </Modal.Header>
      <Modal.Body>
        <div className="subheading-modal mt-3 mb-3" id="accordionFAQ">
          {fullscreen && (
            <div className="subheading-subtle">
              <BsQuestionCircle size={20} />
              &nbsp;&nbsp;{t("FAQ")}
            </div>
          )}
          <div className="accordion-item b-0">
            <h2 className="accordion-header" id="flush-headingOne">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                {t("questionOne")}
              </button>
            </h2>
            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerOne")}</div>
            </div>
          </div>
          <div className="accordion-item b-0">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                {t("questionTwo")}
              </button>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerTwo")}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                {t("questionThree")}
              </button>
            </h2>
            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerThree")}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                {t("questionFour")}
              </button>
            </h2>
            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerFour")}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFive">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                {t("questionFive")}
              </button>
            </h2>
            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerFive")}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSix">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                {t("questionSix")}
              </button>
            </h2>
            <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerSix")}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSeven">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                {t("questionSeven")}
              </button>
            </h2>
            <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerSeven")}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingEight">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                {t("questionEight")}
              </button>
            </h2>
            <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerEight")}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingNine">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                {t("questionNine")}
              </button>
            </h2>
            <div id="flush-collapseNine" className="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerNine")}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTen">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                {t("questionTen")}
              </button>
            </h2>
            <div id="flush-collapseTen" className="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFAQ">
              <div className="accordion-body">{t("answerTen")}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
