import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

const DeleteModal = ({ id, categoryId, placeId, deleteFunction, modalTitle, modalText, ...rest }) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        if (categoryId) {
            dispatch(deleteFunction(id, categoryId));
        } else if (placeId) {
            dispatch(deleteFunction(id, placeId));
        } else {
            dispatch(deleteFunction(id));
        }
        rest.onHide();
    }

    return (
        <Modal {...rest} centered className="caption-modal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                {modalText}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleDelete()}>
                    Yes, delete
                </Button>
                <Button variant="secondary" onClick={() => rest.onHide()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;
