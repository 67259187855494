import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineHeart } from "react-icons/ai";
import { BsImage, BsFillEyeFill } from "react-icons/bs";
import { FiVolume2, FiVolumeX, FiVideo } from "react-icons/fi";
import { TbHeartOff } from "react-icons/tb";
import { stopSound } from "../../reducers/mediaReducer";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { like } from "../../helpers/helpers";
import { likeAction } from "../../reducers/mediaReducer";
import { useLocation } from "react-router-dom";
import defaultImage from "../../Assets/Images/test.jpg"
import "./PlayDropdown.scss";
import PreviewVideoModal from "../../Modals/PreviewVideoModal";

const PlayDropdown = ({ playing, setPlayModalShow }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const match = useLocation();
    const sound = useSelector((state) => state.media.sound);
    const [showPreviewVideoModal, setShowPreviewVideoModal] = useState(false);

    const [playDropdown, setPlayDropdown] = useState(false);
    const togglePlayDropdown = () => setPlayDropdown(!playDropdown);

    const onLikeUnlike = () => {
        like(dispatch, likeAction, playing.id, match);
    };

    let folderText = '';
    if (playing.type === 'upload') {
        folderText = 'My uploads';
    } else if (playing.category_name === playing.place_name) {
        folderText = playing.place_name;
    } else {
        folderText = `${playing.category_name} > ${playing.place_name}`;
    }

    return (
        <>
        <Dropdown
            onToggle={togglePlayDropdown}
            className="header-dropdown secondary-dropdown play-dropdown"
        >
            <Dropdown.Toggle>
                <div className="d-flex align-items-center dropdown-arrow play-underline-dropdown">
                    <div className="d-flex flex-column align-items-end">
                        <span className="small-text small-text-subtle align-self-start">
                            {t("nowPlaying")}
                        </span>
                        <span className="play-music-text">
                            {playing?.display_title}
                        </span>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="image-header">
                    <img
                        alt="media"
                        loading="lazy"
                        src={playing?.url ? playing?.url : defaultImage}
                        className="media-image-header"
                    />
                    {
                        playing?.handle && (
                            <button
                                className="image-button"
                                onClick={() => window.open(playing?.handle, "_blank").focus()}
                            >
                                <IconContext.Provider
                                    value={{ className: "dropdown-icon-image" }}
                                >
                                    <BsFillEyeFill size={20} />
                                </IconContext.Provider>
                                {t("viewIn360")}
                            </button>
                        )
                    }
                    {
                        (playing?.source?.includes('player.vimeo.com') || playing?.video === 1) && (
                            <button
                                className="image-button"
                                onClick={() => setShowPreviewVideoModal(true)}
                            >
                                <IconContext.Provider
                                    value={{ className: "dropdown-icon-image" }}
                                >
                                    <BsFillEyeFill size={20} />
                                </IconContext.Provider>
                                Preview video
                            </button>
                        )
                    }
                </div>
                <div>
                    <IconContext.Provider value={{ className: "media-icon" }}>
                        {playing?.type !== "video" ? (playing?.video !== 1 ? <BsImage /> : <FiVideo />) : <FiVideo />}
                    </IconContext.Provider>
                </div>
                <div className="mb-0 media-title-header">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={playing?.display_title}
                    >
                        <h6 className="text-truncate-container-header">
                            {playing?.display_title}
                        </h6>
                    </span>
                </div>
                <div className="media-text-header">
                    {folderText}
                </div>
                <br />
                {/* Play or stop sound */}
                {(sound?.id && sound?.id > 0 && sound?.title !== 'Inget ljud spelas') ? (
                    <Dropdown.Item className="text-user-tab" onClick={() => dispatch(stopSound())}>
                        <IconContext.Provider
                            value={{ className: "dropdown-icon" }}
                        >
                            <FiVolumeX size={18} />
                        </IconContext.Provider>
                        {t("stopMusic")}
                    </Dropdown.Item>
                ) : (
                    <Dropdown.Item className="text-user-tab" onClick={() => setPlayModalShow(true)}>
                        <IconContext.Provider
                            value={{ className: "dropdown-icon" }}
                        >
                            <FiVolume2 size={18} />
                        </IconContext.Provider>
                        {t("playMusic")}
                    </Dropdown.Item>
                )}
                <Dropdown.Item className="text-user-tab" role="button" onClick={onLikeUnlike}>
                    <IconContext.Provider
                        value={{ className: "dropdown-icon" }}
                    >
                        {playing?.favorite
                            ? <TbHeartOff />
                            : <AiOutlineHeart size={18} />}
                    </IconContext.Provider>
                    {playing?.favorite
                        ? t("removeFavorite")
                        : t("addFavourite")}
                </Dropdown.Item>
                <br/>
                <div className="media-full-text">{playing?.title}</div>
            </Dropdown.Menu>
        </Dropdown>
        { showPreviewVideoModal && (
            <PreviewVideoModal
                show={showPreviewVideoModal}
                source={playing.source}
                title={playing.display_title}
                video={playing.video}
                upload={playing.upload}
                onHide={() => setShowPreviewVideoModal(false)}
            />)
        }
        </>
    )
};

export default PlayDropdown;
