import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { AiOutlineMail, AiOutlineEnvironment } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import "../Modal.scss";

export default function Contact(props) {
  const { t } = useTranslation();

  return (
    <Modal {...props} size="lg" centered className="contact-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          {t("contactUs")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-text">{t('contactUsText')}</div>
        <div>
          <div className="d-flex align-items-center mt-4">
            <div>
              <IconContext.Provider value={{ className: "modal-icon" }}>
                <FiPhone />
              </IconContext.Provider>
            </div>
            <div className="ms-3 modal-info">{t("contactNumber")}</div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <div>
              <IconContext.Provider value={{ className: "modal-icon" }}>
                <AiOutlineMail />
              </IconContext.Provider>
            </div>
            <div className="ms-3 modal-info">{t("contactMail")}</div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <div>
              <IconContext.Provider value={{ className: "modal-icon" }}>
                <AiOutlineEnvironment />
              </IconContext.Provider>
            </div>
            <div className="ms-3 modal-info">{t("contactAddress")}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
