import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Login } from "./Pages/Login/Login";
import { Home } from "./Pages/Home/Home";
import { Favorites } from "./Pages/Favorites/Favorites";
import { Categories } from "./Pages/Categories/Categories";
import { Category } from "./Pages/Category/Category";
import ProtectedRoute from "./Core/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "./reducers/userReducer";
import Panoramas from "./Pages/Panoramas/Panoramas";
import Search from "./Pages/Search/Search";
import AdminRoute from "./Core/AdminRoute";
import AdminPage from "./Pages/Admin/AdminPage";
import Error from "./Pages/Error/Error";
import ForgotPassword from "./Pages/Login/ForgotPassword";

const Router = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user);
  const error = useSelector((state) => state.media.error);

  const setUserToRedux = () => {
    !user && dispatch(updateUser(navigate));
  };

  useEffect(() => {
    setUserToRedux();
    window.addEventListener("storage", setUserToRedux);

    return () => window.removeEventListener("storage", setUserToRedux);
    //  eslint-disable-next-line
  }, []);

  if (error && error !== 401) {
    return <Error />;
  }

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route index element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/category/:category" element={<Category />} />
        <Route path="/panoramas/:id" element={<Panoramas />} />
        <Route path="/search/:term" element={<Search />} />
      </Route>
      <Route element={<AdminRoute />}>
        <Route path="/add" element={<AdminPage />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default Router;
