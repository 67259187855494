import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Header } from "../Components/Header/Header";
import SearchForm from "../Components/Header/SearchForm";

const ProtectedRoute = () => {

  const sidebar = useSelector((state) => state.uiState.sidebar);
  const size = useSelector((state) => state.uiState.size);

  let token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Header />
      <div className={sidebar ? "open-side" : "cont"}>
        <Outlet />
      </div>
      {size < 992 && <SearchForm />}
    </>
  );
};
//Protect routes, navigate to /login if no user, navigate to route he wants if hes logged
export default ProtectedRoute;
