import React from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notification from "./Notification";

const NotificationDropdown = () => {
  const notifications = useSelector((state) => state.user.notifications);
  const navigate = useNavigate();

  const handleNotification = (notification) => {
    if (notification.link) {
      navigate(notification.link);
    }
  }

  return (
    <Dropdown.Menu>
      <div className="wrapper">
        {
          notifications.length === 0 ? (
            <Dropdown.Item
              key={"NoNotifications"}
              className="subheading-notifiction"
            >
              <div className="notification-seen">
                <Notification
                  title={"NoNotifications"}
                  id={"NoNotifications"}
                  content={"NoNotifications"}
                />
              </div>
            </Dropdown.Item>
          ) : (
            notifications.map((notification, i) => {
              return (
                <Dropdown.Item
                  key={i}
                  className="subheading-notifiction"
                  onClick={() => handleNotification(notification)}
                >
                  {notification.seen ? (
                    <div className="notification-seen">
                      <Notification
                        title={notification.title}
                        id={notification.id}
                        content={notification.content}
                        seen={notification.seen}
                        link={notification.link}
                      />
                    </div>
                  ) : (
                    <Notification
                      title={notification.title}
                      id={notification.id}
                      content={notification.content}
                      seen={notification.seen}
                      link={notification.link}
                    />
                  )}
                </Dropdown.Item>
              );
            })
          )
        }
      </div>
    </Dropdown.Menu>
  );
};

export default NotificationDropdown;
