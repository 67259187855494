import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import instance from "../Core/axiosConfig";
import { BsImage } from "react-icons/bs";
import { IconContext } from "react-icons";
import { FiVideo } from "react-icons/fi";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { getPanoramas, getCategories, getPlaces, getPopular, getRecommended, getLastPlayed } from "../reducers/mediaReducer";
import { useLocation } from 'react-router-dom';

const EditMediaModal = ({ panorama, ...rest }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.media.categories);
  const places = useSelector((state) => state.media.places);
  const { t } = useTranslation();
  const location = useLocation();

  const [filterPlaces, setFilterPlaces] = useState([]);
  const [filterPlacesCopy, setFilterPlacesCopy] = useState([]);
  const objectNoSubfolder = {
    id: 'noSubfolder',
    name: 'No Subfolder',
  }

  const [category, setCategory] = useState();
  const [place, setPlace] = useState(null);
  const [inputs, setInputs] = useState({
    display_title: panorama.display_title || "",
    text: panorama.text || "",
    comment: panorama.comment || "",
  });

  useEffect(() => {
    if (categories.length === 0 || places.length === 0) {
      dispatch(getCategories());
      dispatch(getPlaces());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (places.length > 0) {
      places.forEach(place => {
        // comparing int and string, need to be ==
        // eslint-disable-next-line
        if (place.id == panorama.place_id) {
          setPlace(place);
          categories.forEach(category => {
            if (category.id === place.category_id) {
              setNewCategory(category, true);
              return;
            }
          });
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, [places]);

  const setNewCategory = (newCategory, doNotSetPlace) => {
    if (newCategory.id !== category?.id) {
      if (places.length > 0) {
        const newPlaceArray = [];
        places.forEach(place => {
          if (place.category_id === newCategory.id) {
            newPlaceArray.push(place);
          }
        })
        if (newPlaceArray.length > 1) {
          setFilterPlaces(newPlaceArray);
          if (!doNotSetPlace) {
            setPlace(newPlaceArray[0]);
          }
        } else {
          setFilterPlacesCopy(newPlaceArray);
          setFilterPlaces([objectNoSubfolder]);
          if (!doNotSetPlace) {
            setPlace(objectNoSubfolder);
          }
        }
      } else {
        setFilterPlaces([objectNoSubfolder]);
        if (!doNotSetPlace) {
          setPlace(objectNoSubfolder);
        }
      }
      setCategory(newCategory)
    }
  }

  const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

  const getNewData = () => {
    if (location.pathname === '/home') {
      dispatch(getPopular());
      dispatch(getRecommended());
      dispatch(getLastPlayed());
    } else {
      dispatch(getPanoramas(panorama.place_id));
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    let placeId = place.id;
    if (filterPlacesCopy.length === 1 && placeId === objectNoSubfolder.id) {
      placeId = filterPlacesCopy[0].id;
    }
    await instance
      .put(`/panoramas/${panorama.id}`, { display_title: inputs.display_title, panoramaText: inputs.text, placeId: placeId, categoryId: category.id, categoryName: category.title })
      .then((res) => {
        //console.log(res.data);
      })
      .catch((err) => console.log(err));

    instance
      .put(`/comment/${panorama?.id}`, { panoramaComment: inputs.comment })
      .then((res) => {
        //console.log(res.data);
        //setInputs({ text: "", comment: "" });
        rest.onHide();
        getNewData();
      })
      .catch((err) => {
        getNewData();
        console.log(err)
      });
  };

  return (
    <Modal {...rest} centered className="caption-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          Edit panorama
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="d-flex">
          <div className="caption-image w-50 me-2">
            <img className="caption-image w-100" alt="caption" loading="lazy" src={panorama?.thumbnail} />
          </div>
          <div>
            <IconContext.Provider value={{ className: "media-icon" }}>{panorama?.type !== "video" ? <BsImage /> : <FiVideo />}</IconContext.Provider>
            <div className="media-title">{panorama?.title}</div>
            <div className="media-text">{panorama?.category}</div>
          </div>
        </div>
        <Form onSubmit={onSubmit} className="mt-3">
          <Form.Group className="mb-4">
            <Form.Label>{t("displayTitle")}</Form.Label>
            <Form.Control value={inputs.display_title} name="display_title" onChange={onChange} type="text" placeholder={t("displayTitle")} className="textfield textfield-primary" />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t("caption")}</Form.Label>
            <Form.Control value={inputs.text} name="text" onChange={onChange} type="text" as="textarea" rows={4} placeholder={t("caption")} className="textarea textarea-primary" />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t("comment")}</Form.Label>
            <Form.Control value={inputs.comment} name="comment" onChange={onChange} type="text" as="textarea" rows={4} placeholder={t("comment")} className="textarea textarea-primary" />
          </Form.Group>

          <Form.Label className="mt-2">Category</Form.Label>
          <DropdownButton
            required
            drop="down"
            className="dd-menu mb-2 w-100"
            align="end"
            variant="outline-success w-100 d-flex align-items-center justify-content-between"
            title={category?.title ? category.title : "Add to category"}>
            {categories?.map((item, i) => {
              return (
                <Dropdown.Item className="dd-item p-1 m-1" key={i} active={category?.title === item.title} onClick={() => setNewCategory(item)}>
                  {item.title}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <Form.Control.Feedback type="invalid">Category is required</Form.Control.Feedback>

          <Form.Label className="mt-2">Folder</Form.Label>
          <DropdownButton
            required
            drop="down"
            className="dd-menu mb-2 w-100"
            align="end"
            variant="outline-success w-100 d-flex align-items-center justify-content-between"
            title={place?.name ? place.name : "Add to folder"}>
            {filterPlaces?.map((item, i) => {
              return (
                <Dropdown.Item className="dd-item p-1 m-1" key={i} active={place?.name === item.name} onClick={() => setPlace(item)}>
                  {item.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <Form.Control.Feedback type="invalid">Folder is required</Form.Control.Feedback>

          <div className="d-flex justify-content-end">
            <Button size="lg" type="submit" className="primary-button">
              {t("save")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditMediaModal;
