import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Dropdown, Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { AiOutlineHeart } from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import { FiVolume2, FiVolumeX } from "react-icons/fi";
import "./Modal.scss";
import PreviewVideoModal from "./PreviewVideoModal";

const NowPlayingModal = ({ onHide, show, onLikeUnlike, setPanoramaModal, setPlayModalShow, handleStopSound }) => {
  const { t } = useTranslation();
  const playing = useSelector((state) => state.media.nowPlaying);
  const [showPreviewVideoModal, setShowPreviewVideoModal] = useState(false);
  const sound = useSelector((state) => state.media.sound);

  return (
    <>
      <Modal size="sm" centered show={show} onHide={onHide} className="d-flex flex-column align-items-center play-modal">
        <Modal.Header closeButton className="align-items-start ms-2 me-2 ps-2 pe-2 pb-2">
          <div className="d-flex align-items-center justify-content-center pe-3">
            <div className="d-flex flex-column align-items-start mb-1">
              <div className="small-text small-text-subtle">{t("nowPlaying")}</div>
              <div className="play-music-text mb-1 mt-1">{playing?.title}</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-self-center align-items-center justify-content-center w-100 p-0 pb-3">
          <div
            className="dropdown-image ms-3 me-3"
            style={{
              backgroundImage: `url(${playing?.img})`,
              backgroundSize: "cover",
            }}
          />
          {
            playing?.handle && (
              <Dropdown.Item
                role="button"
                disabled={!playing?.handle}
                className="ps-3 m-2"
                onClick={() => {
                  window.open(playing?.handle, "_blank").focus();
                  // onHide();
                  //setPanoramaModal(true);
                  // mozda cemo prikazati u modalu, mozda ne
                }}>
                <IconContext.Provider value={{ className: "dropdown-icon" }}>
                  <BsFillEyeFill />
                </IconContext.Provider>
                {t("viewIn360")}
              </Dropdown.Item>
            )}
          {
            (playing?.source?.includes('player.vimeo.com') || playing?.video === 1) && (
              <Dropdown.Item
                role="button"
                className="ps-3 m-2"
                onClick={() => {
                  setShowPreviewVideoModal(true)
                }}>
                <IconContext.Provider value={{ className: "dropdown-icon" }}>
                  <BsFillEyeFill />
                </IconContext.Provider>
                {t("viewIn360")}
              </Dropdown.Item>
            )}
          {/* Play or stop sound */}
          {(sound?.id && sound?.id > 0 && sound?.title !== 'Inget ljud spelas') ? (
            <Dropdown.Item role="button" className="ps-3 m-2" onClick={handleStopSound}>
              <IconContext.Provider
                value={{ className: "dropdown-icon" }}
              >
                <FiVolumeX />
              </IconContext.Provider>
              {t("stopMusic")}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item role="button" className="ps-3 m-2" onClick={() => setPlayModalShow(true)}>
              <IconContext.Provider
                value={{ className: "dropdown-icon" }}
              >
                <FiVolume2/>
              </IconContext.Provider>
              {t("playMusic")}
            </Dropdown.Item>
          )}
          <Dropdown.Item
            className="ps-3 m-2"
            role="button"
            onClick={() => {
              onHide();
              onLikeUnlike();
            }}>
            <IconContext.Provider value={{ className: "dropdown-icon" }}>
              <AiOutlineHeart />
            </IconContext.Provider>
            {playing?.favorite ? t("removeFavorite") : t("addFavourite")}
          </Dropdown.Item>
        </Modal.Body>
      </Modal>
      {showPreviewVideoModal && (
        <PreviewVideoModal
          show={showPreviewVideoModal}
          source={playing.source}
          title={playing.display_title}
          video={playing.video}
          upload={playing.upload}
          onHide={() => setShowPreviewVideoModal(false)}
        />)
      }
    </>
  );
};

export default NowPlayingModal;
