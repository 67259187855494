import React, { useRef, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import videojs from 'video.js';
import Hls from 'hls.js';
import axios from 'axios';
import 'video.js/dist/video-js.css';

const PreviewVideoModal = ({ source, title, video, upload, ...rest }) => {
    const videoRef = useRef();
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (source?.includes('player.vimeo.com')) {
            axios.get(source, { responseType: 'blob' })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const videoElement = videoRef.current;
                    const videoJsOptions = {
                        sources: [{
                            src: url,
                            type: "application/x-mpegURL"
                        }],
                        controls: true,
                        preload: true,
                        html5: {
                            hls: {
                                enableLowInitialPlaylist: true,
                                smoothQualityChange: true,
                                overrideNative: true,
                            }
                        },
                        playsinline: true
                    };

                    if (Hls.isSupported()) {
                        const hls = new Hls();
                        hls.loadSource(url);
                        hls.attachMedia(videoElement);
                    } else {
                        videoJsOptions.techOrder = ['html5'];
                    }

                    const player = videojs(videoElement, videoJsOptions);

                    return () => {
                        player.dispose();
                    };
                })
                .catch((error) => {
                    console.error('Error downloading m3u8 file:', error);
                });
        }
        //  eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (counter > 0) {
            const videoHeight = videoRef?.current?.offsetHeight;
            const modalBody = document.querySelector('.modal-body');
            modalBody.style.minHeight = `${videoHeight * 1.1}px`;
            modalBody.style.height = `${videoHeight * 1.1}px`;
        }
        setCounter(counter + 1);
        //  eslint-disable-next-line
    }, [videoRef?.current?.style]);

    useEffect(() => {
        const videoElement = videoRef?.current;

        if (videoElement) {
            videoElement.addEventListener(
                "contextmenu",
                function (e) {
                    e.preventDefault();
                },
                false
            );

            const resizeVideo = () => {
                const modalDialog = document.querySelector('.modal-dialog');
                if (modalDialog && videoElement) {
                    const modalWidth = modalDialog.offsetWidth;
                    videoElement.style.minWidth = `${modalWidth * 0.94}px`;
                    videoElement.style.height = 'auto';
                }
            };

            resizeVideo();
            window.addEventListener('resize', resizeVideo);

            return () => {
                videoElement.removeEventListener(
                    "contextmenu",
                    function (e) {
                        e.preventDefault();
                    },
                    false
                );
                window.removeEventListener('resize', resizeVideo);
            };
        }
    }, [videoRef]);

    return (
        <Modal {...rest} centered className="video-preview-modal">
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modal-title"
                >
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="video-preview-modal-body">
                <div>
                    {!source?.includes('player.vimeo.com') ? (
                        <video ref = { videoRef } controls className = "video-js">
                            <source src={source} type="video/mp4" />
                        </video>
                    ): (
                        <video ref = { videoRef } className = "video-js" />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PreviewVideoModal;