import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { BiErrorCircle } from "react-icons/bi";
import { errorModal } from "../reducers/uiStateReducer";

export default function ConfirmationModal() {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.uiState.error);
  const errorMessage = useSelector((state) => state.uiState.errorMessage);

  useEffect(() => {
    show &&
      setTimeout(() => {
        dispatch(errorModal());
      }, 5000);
    //  eslint-disable-next-line
  }, [show]);

  return (
    <Modal show={show} centered>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center h-50 text-danger mt-5 mb-5">
        <BiErrorCircle size={50} />
        <div>{errorMessage}</div>
      </Modal.Body>
    </Modal>
  );
}
