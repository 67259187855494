import React, { useState } from "react";
import { DropdownButton, Dropdown, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addCategory } from "../../reducers/mediaReducer";

const AddCategory = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [swedishName, setSwedishName] = useState("");
  const [size, setSize] = useState("Normal");
  const [position, setPosition] = useState();
  const [validate, setValidate] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [fileInvalid, setFileInvalid] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);
    const form = e.currentTarget;
    if (form.checkValidity()) {
      const data = new FormData();

      data.append("name", name);
      data.append("title_sw", swedishName);
      data.append("thumbnail", thumbnail);

      if(position){
        data.append("sort", position);
      } else {
        data.append("sort", -1);
      }

      if(size === "Normal"){
        data.append("large", false);
      } else {
        data.append("large", true);
      }

      dispatch(addCategory(data));
    }
  };

  const handleFileUpload = (event) => {
    setFileInvalid(false);
    const file = event.target.files[0];
    if(file.type.startsWith("image/")){
      setThumbnail(event.target.files[0]);
    } else {
      setFileInvalid(true);
    }
  }

  const handleInputPosition = (e) => {
    const inputValue = e.target.value;
    
    if (/^[0-9]*$/.test(inputValue)) {
      setPosition(inputValue);
    }
  };

  return (
    <Form noValidate validated={validate} onSubmit={onSubmit} className="form-width d-flex flex-column align-items-center">
      <div className="subheading-modal ml-10  mb-3">Add New Category</div>
      <Form.Label className="mt-2">Category name</Form.Label>
      <Form.Control className="mb-2" placeholder="Category name" required value={name} onChange={(e) => setName(e.target.value)} />
      <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Swedish category name</Form.Label>
      <Form.Control className="mb-2" placeholder="Swedish category name" required value={swedishName} onChange={(e) => setSwedishName(e.target.value)} />
      <Form.Control.Feedback type="invalid">Swedish Name is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Size</Form.Label>
      <DropdownButton
        required
        drop="down"
        className="dd-menu mb-2 w-100"
        align="end"
        variant="outline-success w-100 d-flex align-items-center justify-content-between"
        title={size}>
        <Dropdown.Item className="dd-item p-1 m-1" key={'normalSize'} active={size === 'Normal'} onClick={() => setSize('Normal')}>
            Normal
        </Dropdown.Item>
        <Dropdown.Item className="dd-item p-1 m-1" key={'largeSize'} active={size === 'Large'} onClick={() => setSize('Large')}>
            Large
        </Dropdown.Item>
      </DropdownButton>
      <Form.Control.Feedback type="invalid">Size is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Position number (empty for last)</Form.Label>
      <Form.Control className="mb-2" placeholder="Position number" value={position} onChange={(e) => handleInputPosition(e)} />

      <Form.Label className="mt-2">
        Thumbnail
      </Form.Label>
      <Form.Control type="file" required onChange={(event) => handleFileUpload(event)} accept="image/*"/>
      <Form.Control.Feedback type="invalid">Category thumbnail is required</Form.Control.Feedback>
      {fileInvalid && <Form.Control.Feedback type="invalid">File type is not image</Form.Control.Feedback>}

      <Button type="submit" className="mt-3" variant="success">
        Add Category
      </Button>
    </Form>
  );
};

export default AddCategory;
