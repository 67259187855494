import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addPlace, getCategories } from "../../reducers/mediaReducer";

const AddFolder = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.media.categories);

  const [name, setName] = useState("");
  const [category, setCategory] = useState();
  const [validate, setValidate] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [fileInvalid, setFileInvalid] = useState(false);

  useEffect(() => {
    dispatch(getCategories());
    //  eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);
    const form = e.currentTarget;
    if (form.checkValidity()) {
      const data = new FormData();

      data.append("name", name);
      data.append("category", JSON.stringify(category));
      data.append("thumbnail", thumbnail);

      dispatch(addPlace(data));
      //setName("")
      //setThumbnail(null);
    }
  };

  const handleFileUpload = (event) => {
    setFileInvalid(false);
    const file = event.target.files[0];
    if(file.type.startsWith("image/")){
      setThumbnail(event.target.files[0]);
    } else {
      setFileInvalid(true);
    }
  }

  return (
    <Form noValidate validated={validate} onSubmit={onSubmit} className="form-width d-flex flex-column align-items-center">
      <div className="subheading-modal ml-10  mb-3">Add New Folder</div>
      <Form.Label className="mt-2">Folder name</Form.Label>
      <Form.Control className="mb-2" placeholder="Folder name" required value={name} onChange={(e) => setName(e.target.value)} />
      <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Category</Form.Label>
      <DropdownButton
        required
        drop="down"
        className="dd-menu mb-2 w-100"
        align="end"
        variant="outline-success w-100 d-flex align-items-center justify-content-between"
        title={category?.title ? category.title : "Add to category"}>
        {categories?.map((item, i) => {
          return (
            <Dropdown.Item className="dd-item p-1 m-1" key={i} active={category?.title === item.title} onClick={() => setCategory(item)}>
              {item.title}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <Form.Control.Feedback type="invalid">Category is required</Form.Control.Feedback>

      <Form.Label className="mt-2">
        Thumbnail
      </Form.Label>
      <Form.Control type="file" required onChange={(event) => handleFileUpload(event)} accept="image/*"/>
      <Form.Control.Feedback type="invalid">Folder thumbnail is required</Form.Control.Feedback>
      {fileInvalid && <Form.Control.Feedback type="invalid">File type is not image</Form.Control.Feedback>}

      <Button type="submit" className="mt-3" variant="success">
        Add Folder
      </Button>
    </Form>
  );
};

export default AddFolder;
