import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "../Header/Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { BsQuestionCircle } from "react-icons/bs";
import { BiBell, BiBookmark, BiHeart, BiHome, BiUserX } from "react-icons/bi";
import { IconContext } from "react-icons";
import { FiLogOut } from "react-icons/fi";
import Notifications from "../../Modals/Notifications";
import Flag from "./Flag";
import { notificationRead } from "../../reducers/userReducer";
import { baseURL } from "../../Core/axiosConfig";

const Sidebar = ({ siderbar, sidebarController, setFaqModalShow, signOut, countNotificationUnread }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const notifications = useSelector((state) => state.user.notifications);
  const dispatch = useDispatch();

  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const onModalAction = () => {
    if (showNotificationModal && !user.root && !user.superuser) {
      notifications.forEach((item) => {
        if (!item.seen) {
          dispatch(notificationRead(item.id))
        }
      })
    }
    setShowNotificationModal(!showNotificationModal);
  }

  const [userLogo, setUserLogo] = useState(null);
  useEffect(() => {
    if (user?.companyLogo) {
      setUserLogo(user?.companyLogo);
    }
  }, [user]);

  return (
    <Navbar.Offcanvas
      show={siderbar}
      id="offcanvasNavbar"
      backdropClassName="backdrop"
      aria-labelledby="offcanvasNavbarLabel"
      className="sidebar d-flex align-items-center justify-content-center"
      onHide={sidebarController}>
      <Offcanvas.Body className="d-flex flex-column align-items-center justify-content-evenly w-100">
        <div className="w-75 align-self-center mt-5 ms-3">
          <Flag />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-end w-75 pt-4 mb-4 ms-3">
          {userLogo && (
            <img  src={`${baseURL}/uploads/companies/logo/full-size/${userLogo}`} className="company-logo mb-2" alt="company" />
          )}
          <h5>{user.companyName}</h5>
          <div>
            {user.firstName} {user.lastName}
          </div>
        </div>
        <Nav className="d-flex flex-column justify-content-center align-items-start h-50 top-sidebar-content">
          <NavLink activeclassname="active-sidebar-button" className="sidebar-button d-flex justify-content-start align-items-center p-3 w-100" to="/home" onClick={sidebarController}>
            <BiHome size={20} />
            &nbsp;&nbsp;
            {t("home")}
          </NavLink>
          <NavLink activeclassname="active-sidebar-button" className="sidebar-button d-flex justify-content-start align-items-center p-3 w-100" to="/favorites" onClick={sidebarController}>
            <BiHeart size={20} />
            &nbsp;&nbsp;
            {t("favorites")}
          </NavLink>
          <NavLink activeclassname="active-sidebar-button" className="sidebar-button d-flex justify-content-start align-items-center p-3 w-100" to="/categories" onClick={sidebarController}>
            <BiBookmark size={20} />
            &nbsp;&nbsp;
            {t("categories")}
          </NavLink>
          {user.root && (
            <NavLink activeclassname="active-sidebar-button" className="sidebar-button d-flex justify-content-start align-items-center p-3 w-100 text-danger" to="/add" onClick={sidebarController}>
              <BiUserX size={20} />
              &nbsp;&nbsp;{t("companies")}
            </NavLink>
          )}
        </Nav>
        <div className="d-flex flex-column align-items-start justify-content-center h-25 pt-3 pb-3 middle-sidebar-content">
          <Button variant="light" className="sidebar-button d-flex align-items-center justify-content-between w-100 p-3" onClick={onModalAction}>
            <BiBell size={20} />
            {t("notifications")}
            {countNotificationUnread > 0 ? <div className="notification-sidebar">{countNotificationUnread}</div> : <div style={{ width: "50px" }}></div>}
          </Button>
          <Button
            variant="light"
            onClick={() => {
              setFaqModalShow(true);
            }}
            className="sidebar-button d-flex align-items-center w-100  p-3">
            <IconContext.Provider value={{ className: "icon-subtle" }}>
              <BsQuestionCircle size={20} />
            </IconContext.Provider>
            &nbsp;&nbsp;{t("FAQ")}
          </Button>
        </div>
        <div className="d-flex flex-column justify-content-end align-items-start pt-3 bottom-sidebar-content">
          <Button variant="light" onClick={signOut} className="sidebar-button d-flex align-items-center mb-3 p-3">
            <IconContext.Provider value={{ className: "icon-subtle" }}>
              <FiLogOut size={20} />
            </IconContext.Provider>
            &nbsp;&nbsp;{t("signOut")}
          </Button>
        </div>
      </Offcanvas.Body>
      <Notifications show={showNotificationModal} onHide={onModalAction} />
    </Navbar.Offcanvas>
  );
};

export default Sidebar;
