import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import "./Modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { BsCheck2All } from "react-icons/bs";
import { confirmModal, resetStartedPanorma } from "../reducers/uiStateReducer";
import SuccessPlayed from '../Assets/Icons/SuccessPlayed.svg';

export default function ConfirmationModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.uiState.confirm);
  const startedPanorama = useSelector((state) => state.uiState.startedPanorama);

  useEffect(() => {
    let time = 1000;
    if(startedPanorama?.payload){
      time = 3000;
    }
    show &&
      setTimeout(() => {
        dispatch(confirmModal());
        setTimeout(() => {
          dispatch(resetStartedPanorma());
        }, 1000);
      }, time);
    //  eslint-disable-next-line
  }, [show]);

  return (
    <Modal show={show} centered>
      {startedPanorama?.payload ? (
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center h-50 mt-5 mb-3">
          <img src={SuccessPlayed} alt="icon" />
          <div className="text-played-panorama">{t("confirmationMessage")}</div>
          <div className="description-played-panorama">{t("descriptionPlayedPanorama")}</div>
        </Modal.Body>
      ) : (
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center h-50 text-success mt-5 mb-5">
          <BsCheck2All size={50} />
          <div>{t("confirmationMessage")}</div>
        </Modal.Body>
      )
      }
    </Modal >
  );
}
