import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import instance from "../Core/axiosConfig";
import { confirmModal } from "../reducers/uiStateReducer";
import { BsImage } from "react-icons/bs";
import { IconContext } from "react-icons";
import { FiVideo } from "react-icons/fi";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { getCategory, getCategories } from "../reducers/mediaReducer";

const EditFolderModal = ({ place, ...rest }) => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.media.categories);
    const { t } = useTranslation();

    const [thumbnail, setThumbnail] = useState(null);
    const [fileInvalid, setFileInvalid] = useState(false);

    const [category, setCategory] = useState();
    const [inputs, setInputs] = useState({
        text: place.title || "",
    });

    useEffect(() => {
        if (categories.length === 0) {
            dispatch(getCategories());
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        categories.forEach(category => {
            if (place.category_id === category.id) {
                setCategory(category);
            }
        });
        // eslint-disable-next-line
    }, [categories]);

    const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        await instance
            .put(`/place/${place.id}`, { title: inputs.text, categoryId: category.id })
            .then((res) => {
                dispatch(confirmModal())
                rest.onHide();
                dispatch(getCategory(place.category_id))
            })
            .catch((err) => console.log(err));
    };

    const onUpload = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append("thumbnail", thumbnail);

        await instance
            .post(`/placeThumbnail/${place.id}`, data)
            .then((res) => {
                dispatch(confirmModal())
                rest.onHide();
                dispatch(getCategory(place.category_id))
            })
            .catch((err) => console.log(err));
    };

    const handleFileUpload = (event) => {
        setFileInvalid(false);
        const file = event.target.files[0];
        if (file.type.startsWith("image/")) {
            setThumbnail(event.target.files[0]);
        } else {
            setFileInvalid(true);
        }
    }

    return (
        <Modal {...rest} centered className="caption-modal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
                    Edit folder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="d-flex">
                    <div className="caption-image w-50 me-2">
                        <img className="caption-image w-100" alt="caption" loading="lazy" src={place?.media} />
                    </div>
                    <div>
                        <IconContext.Provider value={{ className: "media-icon" }}>{place?.type !== "video" ? <BsImage /> : <FiVideo />}</IconContext.Provider>
                        <div className="media-title">{place?.title}</div>
                        <div className="media-text">{place?.category}</div>
                    </div>
                </div>
                <Form onSubmit={onSubmit} className="mt-3">
                    <Form.Group className="mb-4">
                        <Form.Control value={inputs.text} name="text" onChange={onChange} type="text" placeholder={t("title")} className="textfield textfield-primary" />
                    </Form.Group>

                    <Form.Label className="mt-2">Category</Form.Label>
                    <DropdownButton
                        required
                        drop="down"
                        className="dd-menu mb-2 w-100"
                        align="end"
                        variant="outline-success w-100 d-flex align-items-center justify-content-between"
                        title={category?.title ? category.title : "Add to category"}>
                        {categories?.map((item, i) => {
                            return (
                                <Dropdown.Item className="dd-item p-1 m-1" key={i} active={category?.title === item.title} onClick={() => setCategory(item)}>
                                    {item.title}
                                </Dropdown.Item>
                            );
                        })}
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid">Category is required</Form.Control.Feedback>

                    <div className="d-flex justify-content-end">
                        <Button size="lg" type="submit" className="primary-button">
                            {t("save")}
                        </Button>
                    </div>
                </Form>
                <Form onSubmit={onUpload} className="mt-3">
                    <Form.Label className="mt-2">
                        Change thumbnail
                    </Form.Label>
                    <Form.Control type="file" required onChange={(event) => handleFileUpload(event)} accept="image/*" />
                    <Form.Control.Feedback type="invalid">Place thumbnail is required</Form.Control.Feedback>
                    {fileInvalid && <Form.Control.Feedback type="invalid">File type is not image</Form.Control.Feedback>}
                    <br />
                    <div className="d-flex justify-content-end">
                        <Button size="lg" type="submit" className="primary-button">
                            Upload image
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditFolderModal;
