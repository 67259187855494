import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import instance from "../Core/axiosConfig";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { getCategories, getPlaces, getCompanyUsers } from "../reducers/mediaReducer";

const AdditionalFolderModal = ({ panorama, ...rest }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.media.categories);
  const places = useSelector((state) => state.media.places);
  const companyUsers = useSelector((state) => state.media.companyUsers);
  const { t } = useTranslation();
  const params = useParams();

  const [filterPlaces, setFilterPlaces] = useState([]);
  const [filterPlacesCopy, setFilterPlacesCopy] = useState([]);
  const objectNoSubfolder = {
    id: 'noSubfolder',
    name: 'No Subfolder',
  }

  const [category, setCategory] = useState();
  const [place, setPlace] = useState(null);

  const objectAll = {
    id: 'all',
    first_name: 'All',
    last_name: ''
  }
  const [userMedia, setUserMedia] = useState(objectAll);

  useEffect(() => {
    if(categories.length === 0 || places.length === 0){
      dispatch(getCategories());
      dispatch(getPlaces());
    }
    dispatch(getCompanyUsers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (places.length > 0) {
      places.forEach(place => {
        // comparing int and string, need to be ==
        // eslint-disable-next-line
        if (place.id == params.id) {
          setPlace(place);
          categories.forEach(category => {
            if (category.id === place.category_id) {
              setNewCategory(category);
              return;
            }
          });
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, [places]);

  const setNewCategory = (newCategory) => {
    if (newCategory.id !== category?.id) {
      if (places.length > 0) {
        const newPlaceArray = [];
        places.forEach(place => {
          if (place.category_id === newCategory.id) {
            newPlaceArray.push(place);
          }
        })
        if(newPlaceArray.length > 1){
          setFilterPlaces(newPlaceArray);
          setPlace(newPlaceArray[0]);
        } else {
          setFilterPlacesCopy(newPlaceArray);
          setFilterPlaces([objectNoSubfolder]);
          setPlace(objectNoSubfolder);
        }
      } else {
        setFilterPlaces([objectNoSubfolder]);
        setPlace(objectNoSubfolder);
      }
      setCategory(newCategory)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    let placeId = place.id;
    if(filterPlacesCopy.length === 1 && placeId === objectNoSubfolder.id){
      placeId = filterPlacesCopy[0].id;
    }
    await instance
      .post(`/duplicatePanorama/${panorama.id}`, { placeId: placeId, categoryId: category.id, categoryName: category.title, userMedia: userMedia.id })
      .then((res) => {
        console.log(res.data);
        rest.onHide();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal {...rest} centered className="caption-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          Add panorama to another category or folder
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <Form onSubmit={onSubmit}>
          <Form.Label className="mt-2">Category</Form.Label>
          <DropdownButton
            required
            drop="down"
            className="dd-menu mb-2 w-100"
            align="end"
            variant="outline-success w-100 d-flex align-items-center justify-content-between"
            title={category?.title ? category.title : "Add to category"}>
            {categories?.map((item, i) => {
              return (
                <Dropdown.Item className="dd-item p-1 m-1" key={i} active={category?.title === item.title} onClick={() => setNewCategory(item)}>
                  {item.title}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <Form.Control.Feedback type="invalid">Category is required</Form.Control.Feedback>

          <Form.Label className="mt-2">Folder</Form.Label>
          <DropdownButton
            required
            drop="down"
            className="dd-menu mb-2 w-100"
            align="end"
            variant="outline-success w-100 d-flex align-items-center justify-content-between"
            title={place?.name ? place.name : "Add to folder"}>
            {filterPlaces?.map((item, i) => {
              return (
                <Dropdown.Item className="dd-item p-1 m-1" key={i} active={place?.name === item.name} onClick={() => setPlace(item)}>
                  {item.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <Form.Control.Feedback type="invalid">Folder is required</Form.Control.Feedback>

          <Form.Label className="mt-2">User</Form.Label>
          <DropdownButton drop="down" className="dd-menu mb-2 w-100" align="end"
            variant="outline-success w-100 d-flex align-items-center justify-content-between" title={`${userMedia.first_name} ${userMedia.last_name}`}>
            <Dropdown.Item className="sync-item p-1 m-1" key={'all'} active={userMedia?.id === objectAll.id} onClick={() => setUserMedia(objectAll)}>
              {objectAll.first_name} {objectAll.last_name}
            </Dropdown.Item>
            {companyUsers?.map((item, i) => {
              return (
                <Dropdown.Item className="sync-item p-1 m-1" key={i} active={userMedia?.id === item?.id} onClick={() => setUserMedia(item)}>
                  {item.first_name} {item.last_name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>

          <div className="d-flex justify-content-end">
            <Button size="lg" type="submit" className="primary-button">
              {t("save")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AdditionalFolderModal;
