import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setError } from "../../reducers/mediaReducer";
import image from "../../Assets/Images/Logos/logo.svg";
import { useTranslation } from "react-i18next";

const Error = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="cont d-flex flex-column align-items-center justify-content-center">
      <img src={image} alt="logo" />
      <div className="mt-3 mb-3">{t("unexpectedError")}</div>
      <Link to="/home" onClick={() => dispatch(setError(null))}>
        {t("home")}
      </Link>
    </div>
  );
};

export default Error;
