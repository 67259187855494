import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { AiOutlineHeart } from "react-icons/ai";
import { Sort } from "../../Components/Sort/Sort";
import MediaCard from "../../Components/Card/MediaCard/MediaCard";
import { FolderCard } from "../../Components/Card/FolderCard/FolderCard";
import { useDispatch, useSelector } from "react-redux";
import {
  panoramas,
  getFavorites,
  getFavoriteFolders,
  favoriteFolders,
} from "../../reducers/mediaReducer";
import { ascending, descending, titleAZ, titleZA } from "../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import media from "../../Assets/Images/nothingHere.png";
import { baseURL } from "../../Core/axiosConfig";

export const Favorites = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const panoramasData = useSelector((state) => state.media.panoramas);
  const favoriteFoldersData = useSelector(
    (state) => state.media.favoriteFolders
  );
  const loading = useSelector((state) => state.uiState.loading);

  useEffect(() => {
    dispatch(getFavorites());
    dispatch(getFavoriteFolders());

    return () => {
      dispatch(panoramas([]));
      dispatch(favoriteFolders([]));
    };
    //  eslint-disable-next-line
  }, []);

  const onFolderClick = (id) => {
    navigate(`/panoramas/${id}`);
  };

  const titleAZSort = (setActiveSort) => {
    titleAZ(favoriteFoldersData, dispatch, favoriteFolders, setActiveSort, [
      "name",
    ]);
    titleAZ(panoramasData, dispatch, panoramas, setActiveSort, ["display_title"]);
  };

  const titleZASort = (setActiveSort) => {
    titleZA(favoriteFoldersData, dispatch, favoriteFolders, setActiveSort, [
      "name",
    ]);
    titleZA(panoramasData, dispatch, panoramas, setActiveSort, ["display_title"]);
  };

  const viewsAscendingSort = (setActiveSort) => {
    ascending(favoriteFoldersData, dispatch, favoriteFolders, setActiveSort, [
      "panoramaCount",
      "name",
    ]);
    ascending(panoramasData, dispatch, panoramas, setActiveSort, [
      "count",
      "title",
    ]);
  };

  const viewsDescendingSort = (setActiveSort) => {
    descending(favoriteFoldersData, dispatch, favoriteFolders, setActiveSort, [
      "panoramaCount",
      "name",
    ]);
    descending(panoramasData, dispatch, panoramas, setActiveSort, [
      "count",
      "title",
    ]);
  };

  if (loading)
    return (
      <div className="page-container">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      </div>
    );

  return (
    <>
      {favoriteFoldersData?.length !== 0 || panoramasData?.length !== 0 ? (
        <div className="page-container">
          <div className="d-flex justify-content-between align-items-center mb-subheader ms-1">
            <div className="d-flex align-items-center ms-1">
              <IconContext.Provider value={{ className: "icon-subtle" }}>
                <AiOutlineHeart />
              </IconContext.Provider>
              <span className="subheading-subtle ml-10">{t("favorites")}</span>
            </div>
            <div>
              <Sort
                titleAZSort={titleAZSort}
                titleZASort={titleZASort}
                viewsAscendingSort={viewsAscendingSort}
                viewsDescendingSort={viewsDescendingSort}
              />
            </div>
          </div>
          <div className="d-flex justify-content-left align-items-left flex-wrap">
            {favoriteFoldersData?.map((place) => {
              return (
                <FolderCard
                  key={place.id}
                  favorite={place.favorite}
                  id={place.id}
                  title={place.name}
                  size="large"
                  media={place.thumbnail ? `${baseURL}/placeThumbnail/${place.id}` : null}
                  count={place.panoramaCount}
                  onClick={() => onFolderClick(place.id)}
                  recommended={place.recommended}
                  isFavorite={true}
                />
              );
            })}
            {panoramasData?.map((panorama) => {
              return (
                <MediaCard
                  key={panorama.id}
                  panorama={panorama}
                  img={panorama.thumbnail}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="page-container-no-favorites">
          <img alt="no favorites" src={media} />
          <div className="no-favorites-text">
            <h5 className="text-secondary fw-bold text-center">
              Looks like you've not added any favorites yet.
            </h5>
            <h6 className="text-secondary text-center">
              Use the heart icon to move them here!
            </h6>
          </div>
        </div>
      )}
    </>
  );
};
