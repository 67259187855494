import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sort } from "../../Components/Sort/Sort";
import { FolderCard } from "../../Components/Card/FolderCard/FolderCard";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategory, getCategories, setCategory, setCategoryTitle, getPanoramas, panoramas } from "../../reducers/mediaReducer";
import { Spinner } from "react-bootstrap";
import { ascending, descending, titleAZ, titleZA } from "../../helpers/helpers";
import { baseURL } from "../../Core/axiosConfig";
import MediaCard from "../../Components/Card/MediaCard/MediaCard";

export const Category = () => {
  const id = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.media.categories);
  const category = useSelector((state) => state.media.category);
  const categoryTitle = useSelector((state) => state.media.categoryTitle);
  const [selectedCategory, setSelectedCategory] = useState();
  const panoramasData = useSelector((state) => state.media.panoramas);

  useEffect(() => {
    dispatch(getCategory(id.category));
    dispatch(panoramas([]))
    if (categories.length === 0) {
      dispatch(getCategories())
    }

    return () => dispatch(setCategory());

    //  eslint-disable-next-line
  }, [id.category]);

  useEffect(() => {
    categories.forEach(element => {
      // comparing int and string, need to be ==
      //  eslint-disable-next-line
      if (element.id == id.category) {
        dispatch(setCategoryTitle(element.title));
        setSelectedCategory(element);
        return;
      }
    });
    //  eslint-disable-next-line
  }, [categories, id.category]);

  useEffect(() => {
    if (category) {
      category.forEach((item) => {
        if (item.name === categoryTitle || item.name === selectedCategory.title_sw) {
          dispatch(getPanoramas(item.id));
        }
      })
    }
    //  eslint-disable-next-line
  }, [category, categoryTitle]);

  const onFolderClick = (id) => {
    navigate(`/panoramas/${id}`);
  };

  const titleAZSort = (setActiveSort) => {
    titleAZ(category, dispatch, setCategory, setActiveSort, ["name"]);
  };

  const titleZASort = (setActiveSort) => {
    titleZA(category, dispatch, setCategory, setActiveSort, ["name"]);
  };

  const viewsAscendingSort = (setActiveSort) => {
    ascending(category, dispatch, setCategory, setActiveSort, ["panoramaCount", "name"]);
  };

  const viewsDescendingSort = (setActiveSort) => {
    descending(category, dispatch, setCategory, setActiveSort, ["panoramaCount", "name"]);
  };

  return (
    <div className="page-container">
      <div className="d-flex justify-content-between align-items-center mb-subheader">
        <div className="subheading-subtle ml-10">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-categories">
              <li className="breadcrumb-item">
                <Link to="/categories">{t("categories")}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {t(categoryTitle)}
              </li>
            </ol>
          </nav>
        </div>
        <Sort titleAZSort={titleAZSort} titleZASort={titleZASort} viewsAscendingSort={viewsAscendingSort} viewsDescendingSort={viewsDescendingSort} />
      </div>
      {category ? (
        <div className="d-flex justify-content-start w-100 align-items-center flex-wrap">
          {category?.map((place) => {
            if ((place.name === categoryTitle || place.name === selectedCategory.title_sw) && panoramasData?.length > 0) {
              return null;
            }
            return (
              <FolderCard
                key={place.id}
                favorite={place.favorite}
                id={place.id}
                title={place.name}
                size={panoramasData?.length > 0 ? ("small-category") : ("small")}
                category_id={place.category_id}
                media={place.thumbnail ? `${baseURL}/placeThumbnail/${place.id}` : null}
                count={place.panoramaCount}
                onClick={() => onFolderClick(place.id)}
                recommended={place.recommended}
              />
            );
          })}
          {panoramasData.map((panorama) => {
            return <MediaCard key={panorama.id} panorama={panorama} img={panorama.thumbnail} />;
          })}
        </div>
      ) : (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
    </div>
  );
};
