import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsImage } from "react-icons/bs";
import { BiTrash, BiPencil } from "react-icons/bi";
import { FiVideo, FiInfo } from "react-icons/fi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { LikeHeart } from "../../Heart/LikeHeart/LikeHeart";
import { UnlikeHeart } from "../../Heart/UnlikeHeart/UlikeHeart";
import "../Card.scss";
import DeleteModal from "../../../Modals/DeleteModal";
import EditPanoramaModal from "../../../Modals/EditMediaModal";
import UserEditMediaModal from "../../../Modals/UserEditMediaModal";
import AdditionalFolderModal from "../../../Modals/AdditionalFolderModal";
import SyncCard from "./SyncCard";
import defaultImage from "../../../Assets/Images/test.jpg";
import { deleteMedia, likeAction, playPanorama } from "../../../reducers/mediaReducer";
import AddToRecommended from "../AddToRecommended";

const MediaCard = ({ panorama, img, searchCard, isHome, homeRecommended = '' }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let folderText = '';
  if (panorama.type === 'upload' && !panorama?.source?.includes('player.vimeo.com')) {
    folderText = 'My uploads';
  } else if (panorama.category_name === panorama.place_name || t(panorama.category_name) === t(panorama.place_name)) {
    folderText = t(panorama.place_name);
  } else {
    folderText = `${t(panorama.category_name)} > ${t(panorama.place_name)}`;
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUserEditMediaModal, setShowUserEditMediaModal] = useState(false);
  const [showAdditionalFolderModal, setShowAdditionalFolderModal] = useState(false);

  let isOutsideSearch = searchCard && searchCard();

  const onClick = () => {
    dispatch(
      playPanorama({
        panorama: panorama
      })
    );
  };

  return (
    <>
      <Card
        onClick={() => !isOutsideSearch && onClick()}
        className={`position-relative media-card${homeRecommended}`}
        role="button"
      >
        {!isOutsideSearch && (
          <div>
            {!panorama?.favorite ? (
              <LikeHeart id={panorama?.id} action={likeAction} />
            ) : (
              <UnlikeHeart id={panorama?.id} action={likeAction} />
            )}
            {user?.superUser ? (
              <>
                <div
                  role="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowEditModal(true);
                  }}
                  className="position-absolute d-flex justify-content-center align-items-center pencil "
                >
                  <IconContext.Provider value={{ className: "pencil-icon" }}>
                    <BiPencil />
                  </IconContext.Provider>
                </div>
                <div
                  role="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteModal(true);
                  }}
                  className="position-absolute d-flex justify-content-center align-items-center trash "
                >
                  <IconContext.Provider value={{ className: "trash-icon" }}>
                    <BiTrash />
                  </IconContext.Provider>
                </div>
              </>
            ) : (
              <div
                  role="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowUserEditMediaModal(true);
                  }}
                  className="position-absolute d-flex justify-content-center align-items-center pencil"
                >
                  <IconContext.Provider value={{ className: "info-icon" }}>
                    <FiInfo />
                  </IconContext.Provider>
                </div>
            )}
          </div>
        )}
        <img
          alt="media"
          loading="lazy"
          src={img ? img : defaultImage}
          className="media-image"
        />
        <Card.Body className="d-flex flex-column justify-content-start align-items-start media-body">
          {/*TO DO: image/video */}
          <div className="d-flex align-items-center justify-content-between w-100">
            <IconContext.Provider value={{ className: "media-icon" }}>
              {(panorama?.type === "video" || panorama?.video === 1 || panorama?.source?.includes('player.vimeo.com')) ? <FiVideo/> : <BsImage />}
              {/* <FiVideo /> */}
            </IconContext.Provider>
            {!isOutsideSearch && user?.root && (
              <div className="d-flex flex-row" style={{ gap: '5px' }}>
                <div className="mb-1">
                  <IoMdAddCircleOutline
                    title={'Add to additional category'}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAdditionalFolderModal(true);
                    }}
                    role="button"
                    color="green"
                    size={22}
                  />
                </div>
                <AddToRecommended
                  handle={`id=${panorama?.id}`}
                  isFolder={false}
                  isRecommended={panorama?.recommended}
                  id={panorama?.id}
                  isHome={isHome}
                />
              </div>
            )}
          </div>
          <Card.Title className="mb-0 media-title">
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title={isOutsideSearch ? panorama.title : panorama?.display_title}
            >
              <h6 className="text-truncate-container">
                {isOutsideSearch ? panorama.title : panorama?.display_title}
              </h6>
            </span>
          </Card.Title>
          <Card.Text className="media-text">
            {isOutsideSearch ? isOutsideSearch.tag : folderText}
          </Card.Text>
        </Card.Body>
        {/*Render this if user is on search page, other conditionals are for Sync button/Spinner/Synced*/}
        {isOutsideSearch && <SyncCard searchCard={isOutsideSearch} panorama={panorama} />}
      </Card>
      {showDeleteModal && (<DeleteModal
        id={panorama.id}
        deleteFunction={deleteMedia}
        modalTitle={`Delete media '${panorama.display_title}'?`}
        modalText="Are you sure you want to delete this media ? This action cannot be reverted!"
        placeId={panorama.place_id}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      />)}
      {showEditModal && (
        <EditPanoramaModal
          panorama={panorama}
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
        />
      )}
      {showUserEditMediaModal && (
        <UserEditMediaModal
          panorama={panorama}
          show={showUserEditMediaModal}
          onHide={() => setShowUserEditMediaModal(false)}
        />
      )}
      {showAdditionalFolderModal && (
        <AdditionalFolderModal
          panorama={panorama}
          show={showAdditionalFolderModal}
          onHide={() => setShowAdditionalFolderModal(false)}
        />
      )}
    </>
  );
};
export default memo(MediaCard);
