import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import instance from "../Core/axiosConfig";
import { confirmModal } from "../reducers/uiStateReducer";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { getCategories, setCategories } from "../reducers/mediaReducer";

const EditFolderModal = ({ category, ...rest }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [thumbnail, setThumbnail] = useState(null);
    const [fileInvalid, setFileInvalid] = useState(false);
    const [size, setSize] = useState(category.size === 'large' ? 'Large' : 'Normal');
    const [position, setPosition] = useState(category.sort);

    const [inputs, setInputs] = useState({
        text: category.title || "",
        swedishName: category.title_sw || "",
    });

    const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        await instance
            .put(`/category/${category.id}`, { 
                title: inputs.text, 
                title_sw: inputs.swedishName, 
                large: size === 'Large' ? true : false,
                sort: position,
            })
            .then((res) => {
                dispatch(confirmModal())
                rest.onHide();
                dispatch(getCategories())
            })
            .catch((err) => console.log(err));
    };

    const onUpload = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append("thumbnail", thumbnail);

        await instance
            .post(`/categoryThumbnail/${category.id}`, data)
            .then((res) => {
                dispatch(confirmModal())
                rest.onHide();
                dispatch(setCategories([]))
                dispatch(getCategories())
            })
            .catch((err) => console.log(err));
    };

    const handleFileUpload = (event) => {
        setFileInvalid(false);
        const file = event.target.files[0];
        if (file.type.startsWith("image/")) {
            setThumbnail(event.target.files[0]);
        } else {
            setFileInvalid(true);
        }
    }

    const handleInputPosition = (e) => {
        const inputValue = e.target.value;

        if (/^[0-9]*$/.test(inputValue)) {
            setPosition(inputValue);
        }
    };

    return (
        <Modal {...rest} centered className="caption-modal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
                    Edit category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="d-flex">
                    <div className="caption-image w-50 me-2">
                        <img className="caption-image w-100" alt="caption" loading="lazy" src={category?.media} />
                    </div>
                    <div>
                        <div className="media-title">{category?.title}</div>
                    </div>
                </div>
                <Form onSubmit={onSubmit} className="mt-3">
                    <Form.Label>Category name</Form.Label>
                    <Form.Group className="mb-2">
                        <Form.Control value={inputs.text} name="text" onChange={onChange} type="text" placeholder={t("title")} className="textfield textfield-primary" />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">Category name is required</Form.Control.Feedback>

                    <Form.Label className="mt-2">Swedish category name</Form.Label>
                    <Form.Group className="mb-2">
                        <Form.Control value={inputs.swedishName} name="swedishName" onChange={onChange} type="text" placeholder={"Swedish category name"} className="textfield textfield-primary" />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">Swedish name is required</Form.Control.Feedback>

                    <Form.Label className="mb-4 mt-2">Size</Form.Label>
                    <DropdownButton
                        required
                        drop="down"
                        className="dd-menu mb-2 w-100"
                        align="end"
                        variant="outline-success w-100 d-flex align-items-center justify-content-between"
                        title={size}>
                        <Dropdown.Item className="dd-item p-1 m-1" key={'normalSize'} active={size === 'Normal'} onClick={() => setSize('Normal')}>
                            Normal
                        </Dropdown.Item>
                        <Dropdown.Item className="dd-item p-1 m-1" key={'largeSize'} active={size === 'Large'} onClick={() => setSize('Large')}>
                            Large
                        </Dropdown.Item>
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid">Size is required</Form.Control.Feedback>

                    <Form.Label className="mt-2">Position number (empty for last)</Form.Label>
                    <Form.Control className="textfield textfield-primary mb-2" placeholder="Position number" value={position} onChange={(e) => handleInputPosition(e)} />

                    <div className="d-flex justify-content-end">
                        <Button size="lg" type="submit" className="primary-button">
                            {t("save")}
                        </Button>
                    </div>
                </Form>
                <Form onSubmit={onUpload} className="mt-3">
                    <Form.Label className="mt-2">
                        Change thumbnail
                    </Form.Label>
                    <Form.Control type="file" required onChange={(event) => handleFileUpload(event)} accept="image/*" />
                    <Form.Control.Feedback type="invalid">Category thumbnail is required</Form.Control.Feedback>
                    {fileInvalid && <Form.Control.Feedback type="invalid">File type is not image</Form.Control.Feedback>}
                    <br />
                    <div className="d-flex justify-content-end">
                        <Button size="lg" type="submit" className="primary-button">
                            Upload image
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditFolderModal;
