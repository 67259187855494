import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form, Button } from "react-bootstrap";
import { BsImage } from "react-icons/bs";
import { IconContext } from "react-icons";
import { FiVideo } from "react-icons/fi";
import { getPanoramas, getPopular, getRecommended, getLastPlayed } from "../reducers/mediaReducer";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import instance from "../Core/axiosConfig";

const UserEditMediaModal = ({ panorama, ...rest }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    const [inputs, setInputs] = useState({
        display_title: panorama.display_title || "",
        text: panorama.text || "",
        comment: panorama.comment || "",
    });

    const getNewData = () => {
        if (location.pathname === '/home') {
            dispatch(getPopular());
            dispatch(getRecommended());
            dispatch(getLastPlayed());
        } else {
            dispatch(getPanoramas(panorama.place_id));
        }
    }

    const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault()
        await instance
            .put(`/panoramas/${panorama.id}`, { panoramaText: inputs.text, })
            .then((res) => {
                //console.log(res.data);
            })
            .catch((err) => console.log(err));

        instance
            .put(`/comment/${panorama?.id}`, { panoramaComment: inputs.comment })
            .then((res) => {
                //console.log(res.data);
                //setInputs({ text: "", comment: "" });
                rest.onHide();
                getNewData();
            })
            .catch((err) => {
                getNewData();
                console.log(err)
            });
    }

    return (
        <Modal {...rest} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
                    {t('information')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="d-flex">
                    <div className="user-caption-image me-2">
                        <img className="caption-image w-100" alt="caption" loading="lazy" src={panorama?.thumbnail} />
                    </div>
                    <div>
                        <IconContext.Provider value={{ className: "media-icon" }}>{panorama?.type !== "video" ? <BsImage /> : <FiVideo />}</IconContext.Provider>
                        <div className="media-title">{panorama?.display_title}</div>
                        <div className="media-text">{panorama?.place_name}</div>
                    </div>
                </div>
                <Form onSubmit={onSubmit} className="mt-3">
                    {panorama.text && (
                        <Form.Group className="mb-4">
                            <Form.Label><b>{t("factsAboutDestination")}</b></Form.Label>
                            <br />
                            {panorama.text}
                        </Form.Group>
                    )}
                    <Form.Group className="mb-4">
                        <Form.Label><b>{t("VRtext")}</b></Form.Label>
                        <Form.Control value={inputs.comment} name="comment" onChange={onChange} type="text" as="textarea" rows={4} placeholder={t("placehoderVRtext")} className="textarea textarea-primary" />
                    </Form.Group>

                    <div className="d-flex justify-content-end">
                        <Button size="lg" type="submit" className="primary-button">
                            {t("save")}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UserEditMediaModal;
