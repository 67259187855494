import React, { useState, useEffect } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import AddCompany from "./AddCompany";
import AddFolder from "./AddFolder";
import CreateNotification from "./CreateNotification";
import "./Admin.scss";
import Reports from "./Reports";
import AddUser from "./AddUser";
import AddMedia from "./AddMedia";
import Settings from "./Settings";
import { useDispatch } from "react-redux";
import { getCompanies } from "../../reducers/mediaReducer";
import AddCategory from "./AddCategory";

const AdminPage = () => {
  const [active, setActive] = useState("category");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanies());
    // eslint-disable-next-line
  }, []);

  const onClick = (e) => setActive(e.target.name);

  return (
    <div className="page-container">
      <ButtonGroup className="d-sm-flex">
        <Button name="category" variant={active === "category" ? "success" : "secondary"} onClick={onClick}>
          Add Category
        </Button>
        <Button name="folder" variant={active === "folder" ? "success" : "secondary"} onClick={onClick}>
          Add Folder
        </Button>
        <Button name="company" variant={active === "company" ? "success" : "secondary"} onClick={onClick}>
          Add Company
        </Button>
        <Button name="user" variant={active === "user" ? "success" : "secondary"} onClick={onClick}>
          Add User
        </Button>
        <Button name="notification" variant={active === "notification" ? "success" : "secondary"} onClick={onClick}>
          Add Notification
        </Button>
        <Button name="media" variant={active === "media" ? "success" : "secondary"} onClick={onClick}>
          Add Media
        </Button>
        <Button name="reports" variant={active === "reports" ? "success" : "secondary"} onClick={onClick}>
          Check Reports
        </Button>
        <Button name="settings" variant={active === "settings" ? "success" : "secondary"} onClick={onClick}>
          Settings
        </Button>
      </ButtonGroup>
      <div className="d-flex align-self-center mt-5">
        {active === "category" ? <AddCategory /> : active === "folder" ? <AddFolder /> : active === "notification" ? <CreateNotification /> : active === "reports" ? <Reports /> : active === "user" ? <AddUser /> : active === "media" ? <AddMedia /> : active === "settings" ? <Settings /> : <AddCompany />}
      </div>
    </div>
  );
};

export default AdminPage;
