import React from "react";
import { IconContext } from "react-icons";
import { FiChevronsRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const Notification = ({ onClick, title, content, link }) => {
  const { t } = useTranslation();

  return (
    <>
      {title !== 'NoNotifications' && (
        <div className="d-flex justify-content-between align-items-center w-100" role="button" onClick={onClick}>
          <span className="notification-title">{title}</span>
          {link && (
            <IconContext.Provider value={{ className: "notification-subheading-icon" }}>
              <FiChevronsRight />
            </IconContext.Provider>
          )}
        </div>
      )
      }
      {title === 'NoNotifications' ? (
        <div className="notification-text">{t(content)}</div>
      ) : (
        <div className="notification-text">{content}</div>
      )
      }
    </>
  );
};
export default Notification;
