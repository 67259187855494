import _ from "lodash";

export const like = (dispatch, action, id, match) => {
   dispatch(action({ id: id, match: match.pathname }))
};

export const titleAZ = (data, dispatch, action, setActiveSort, sortBy) => {
  let sorted = _.orderBy(data, sortBy, "asc");
  dispatch(action(sorted));
  setActiveSort("titleAZ");
};

export const titleZA = (data, dispatch, action, setActiveSort, sortBy) => {
  let sorted = _.orderBy(data, sortBy, "desc");
  dispatch(action(sorted));
  setActiveSort("titleZA");
};

export const ascending = (data, dispatch, action, setActiveSort, sortBy) => {
  let sorted = _.orderBy(data, sortBy, ["asc", "asc"]);
  dispatch(action(sorted));
  setActiveSort("viewsAscending");
};

export const descending = (data, dispatch, action, setActiveSort, sortBy) => {
  let sorted = _.orderBy(data, sortBy, ["desc", "asc"]);
  dispatch(action(sorted));
  setActiveSort("viewsDescending");
};