import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addCompany } from "../../reducers/mediaReducer";

const AddCompany = () => {
  const dispatch = useDispatch();
  const [fileInvalid, setFileInvalid] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    description: "",
    logo: null,
  });
  const [validate, setValidate] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);
    const form = e.currentTarget;
    if (form.checkValidity()) {
      const data = new FormData();

      data.append("name", inputs.name);
      data.append("description", inputs.description);
      data.append("logo", inputs.logo);

      dispatch(addCompany(data));
    }
  };

  const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

  const handleFileUpload = (event) => {
    setFileInvalid(false);
    const file = event.target.files[0];
    if(file.type.startsWith("image/")){
      setInputs({ ...inputs, logo: event.target.files[0] });
    } else {
      setFileInvalid(true);
    }
  }

  return (
    <div className="d-flex">
      <Form noValidate validated={validate} onSubmit={onSubmit} className="form-width d-flex flex-column align-items-center me-5">
        <div className="subheading-modal ml-10  mb-3">Add New Company</div>
        <Form.Label className="mt-2">Name</Form.Label>
        <Form.Control name="name" className="mb-2" placeholder="Name" required value={inputs.name} onChange={onChange} />
        <Form.Control.Feedback type="invalid">Company name is required</Form.Control.Feedback>
        <Form.Label className="mt-2">Description</Form.Label>
        <Form.Control name="description" className="mb-2" placeholder="Description" value={inputs.description} onChange={onChange} />
        <Form.Label className="mt-2">
          Logo
        </Form.Label>
        <Form.Control type="file" required onChange={(e) => handleFileUpload(e)} accept="image/*" />
        <Form.Control.Feedback type="invalid">Company logo is required</Form.Control.Feedback>
        {fileInvalid && <Form.Control.Feedback type="invalid">File type is not image</Form.Control.Feedback>}
        <Button type="submit" variant="success" className="mt-3">
          Add Company
        </Button>
      </Form>
    </div>
  );
};

export default AddCompany;
