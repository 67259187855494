import React, { useState, useEffect } from "react";
import { Button, Spinner, Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import instance from "../../../Core/axiosConfig";
import "../Card.scss";
import { useTranslation } from "react-i18next";

const SyncCard = ({ searchCard, panorama }) => {
  const { t } = useTranslation();
  const categories = useSelector((state) => state.media.categories);
  const places = useSelector((state) => state.media.places);
  const companyUsers = useSelector((state) => state.media.companyUsers);

  const [synced, setSynced] = useState(false);
  const [synchronizing, setSynchronizing] = useState(false);
  const [category, setCategory] = useState(null);
  const [place, setPlace] = useState(null);
  const [filterPlaces, setFilterPlaces] = useState([]);
  const [filterPlacesCopy, setFilterPlacesCopy] = useState([]);
  const objectNoSubfolder = {
    id: 'noSubfolder',
    name: 'No Subfolder',
  }

  const objectAll = {
    id: 'all',
    first_name: 'All',
    last_name: ''
  }
  const [userMedia, setUserMedia] = useState(objectAll);

  useEffect(() => {
    setNewCategory(categories[0]);
    // eslint-disable-next-line
  }, [categories]);

  const setNewCategory = (newCategory) => {
    if (!newCategory) {
      return;
    }
    if (newCategory.id !== category?.id) {
      if (places.length > 0) {
        const newPlaceArray = [];
        places.forEach(place => {
          if (place.category_id === newCategory.id) {
            newPlaceArray.push(place);
          }
        })
        if(newPlaceArray.length > 1){
          setFilterPlaces(newPlaceArray);
          setPlace(newPlaceArray[0]);
        } else {
          setFilterPlacesCopy(newPlaceArray);
          setFilterPlaces([objectNoSubfolder]);
          setPlace(objectNoSubfolder);
        }
      } else {
        setFilterPlaces([objectNoSubfolder]);
        setPlace(objectNoSubfolder);
      }
      setCategory(newCategory)
    }
  }

  const onSyncClick = () => {
    setSynchronizing(true);
    let placeId = place.id;
    if(filterPlacesCopy.length === 1 && placeId === objectNoSubfolder.id){
      placeId = filterPlacesCopy[0].id;
    }
    instance
      .post("/panoramacube", { handle: searchCard.handle, categoryId: category.id, categoryName: category.title, place: placeId, userMedia: userMedia.id, embed_url: panorama.embed_url })
      .then(() => {
        setSynced(true);
        setSynchronizing(false);
      })
      .catch((err) => console.log(err));
  };
  //SYNC PANORAMA TO SERVER, ADD TO CATEGORY.

  return !synced ? (
    <div className="sync-card mb-1">
      {synchronizing ? (
        <Spinner animation="border" variant="secondary" />
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center mb-1 w-100">
          <DropdownButton drop="down" className="sync-dd mb-1" variant="outline-success" title={category?.title}>
            {categories?.map((item, i) => {
              return (
                <Dropdown.Item className="sync-item p-1 m-1" key={i} active={category?.title === item.title} onClick={() => setNewCategory(item)}>
                  {item.title}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <DropdownButton drop="down" className="sync-dd mb-1" variant="outline-success" title={place?.name}>
            {filterPlaces?.map((item, i) => {
              return (
                <Dropdown.Item className="sync-item p-1 m-1" key={i} active={place?.name === item.name} onClick={() => setPlace(item)}>
                  {item.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <DropdownButton drop="down" className="sync-dd mb-1" variant="outline-success" title={`${userMedia.first_name} ${userMedia.last_name}`}>
            <Dropdown.Item className="sync-item p-1 m-1" key={'all'} active={userMedia?.id === objectAll.id} onClick={() => setUserMedia(objectAll)}>
              {objectAll.first_name} {objectAll.last_name}
            </Dropdown.Item>
            {companyUsers?.map((item, i) => {
              return (
                <Dropdown.Item className="sync-item p-1 m-1" key={i} active={userMedia?.id === item?.id} onClick={() => setUserMedia(item)}>
                  {item.first_name} {item.last_name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <Button onClick={onSyncClick} variant="outline-success">
            {t("sync")}
          </Button>
        </div>
      )}
    </div>
  ) : (
    <div className="sync-card small-text-brand pb-5 fs-5">{t("synced")}</div>
  );
};

export default SyncCard;
