import axios from "axios";

//API link for local server
//export const baseURL = "http://localhost:8000";
//API link for dev server
//export const baseURL = "https://dev-api.virotea.com";
//API link for live server
export const baseURL = "https://api.virotea.com";

const instance = axios.create({
  baseURL: baseURL,
});

const currentUser = localStorage.getItem("token");

if (currentUser) {
  instance.defaults.headers.common["Authorization"] = currentUser;
}
//add current user token to authorization header

export default instance;
