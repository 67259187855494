import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../reducers/userReducer";

const AddUser = () => {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.media.companies);

  const [inputs, setInputs] = useState({
    userName: "",
    userEmail: "",
    userPassword: "",
    userLastName: "",
    company: companies[0]?.id?.toString(),
  });

  const [validate, setValidate] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setValidate(true);
    const form = e.currentTarget;
    if (form.checkValidity()) {
      dispatch(addUser(inputs));
    }
  };

  const onChange = (e) => {
    if (e.target.name === 'company_id') {
      setInputs({ ...inputs, company: e.target.value })
      return;
    }
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  return (
    <Form noValidate validated={validate} onSubmit={onSubmit} className="form-width d-flex flex-column align-items-center">
      <div className="subheading-modal ml-10  mb-3">Add User</div>
      <Form.Label className="mt-2">Company</Form.Label>
      <Form.Select required name="company_id" value={inputs.company_id} onChange={onChange} className="mb-2">
        {companies?.map((company, i) => {
          return (
            <option key={i} value={company.id}>
              {company.name}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">Company is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Email</Form.Label>
      <Form.Control name="userEmail" type="email" className="mb-2" placeholder="Email" required value={inputs.userEmail} onChange={onChange} />
      <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>

      <Form.Label className="mt-2">First Name</Form.Label>
      <Form.Control name="userName" className="mb-2" placeholder="First name" required value={inputs.userName} onChange={onChange} />
      <Form.Control.Feedback type="invalid">First name is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Last Name</Form.Label>
      <Form.Control name="userLastName" className="mb-2" placeholder="Last name" required value={inputs.userLastName} onChange={onChange} />
      <Form.Control.Feedback type="invalid">Last name is required</Form.Control.Feedback>

      <Form.Label className="mt-2">Password</Form.Label>
      <Form.Control name="userPassword" autoComplete="new-password" type="password" className="mb-2" placeholder="Password" required value={inputs.userPassword} onChange={onChange} />
      <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>

      <Button type="submit" className="mt-3" variant="success">
        Add User
      </Button>
    </Form>
  );
};

export default AddUser;
